export const nameLeagueMap: { [key in string]: string } = {
  ["first-league"]: "Первая лига",
  ["second-league"]: "Вторая лига А",
  ["third-league"]: "Вторая лига Б",
};

export const nameLeagueTitle: { [key in string]: string } = {
  ["first-league"]: "'Где смотреть?'. Первая лига",
  ["second-league"]: "'Где смотреть?'. Вторая лига А",
  ["third-league"]: "'Где смотреть?'.Вторая лига Б",
};

export const leagueIndex: { [key in string]: string } = {
  ["first-league"]: "100",
  ["second-league"]: "200",
  ["third-league"]: "300",
};

export const translationsTypesSelect = [
  {
    key: "vk",
    value: "Вконтакте",
  },
  // {
  //   key: "youtube",
  //   value: "Youtube",
  // },
  {
    key: "match-tv",
    value: "Матч тв",
  },
  {
    key: "match-premier",
    value: "Матч премьер",
  },
  {
    key: "kinopoisk",
    value: "Кинопоиск",
  },
  {
    key: "tricolor",
    value: "Триколор",
  },
];

export const mediaTypeSelect = [
  {
    key: "PHOTO",
    value: "Альбом изображений",
  },
  {
    key: "LINK",
    value: "Ссылка",
  },
];

export const colorTypeSelect = [
  {
    key: "#FFBC00",
    value: "Yellow 1",
  },
  {
    key: "#FF9A25",
    value: "Yellow 2",
  },
  {
    key: "#EB6300",
    value: "Yellow 3",
  },
  {
    key: "#009A3B",
    value: "Green 1",
  },
  {
    key: "#007A23",
    value: "Green 2",
  },
  {
    key: "#007950",
    value: "Green 3",
  },
  {
    key: "#00A6E4",
    value: "Blue 1",
  },
  {
    key: "#17BDEA",
    value: "Blue 2",
  },
  {
    key: "#005096",
    value: "Blue 3",
  },
  {
    key: "#F6303A",
    value: "Red 1",
  },
  {
    key: "#D62628",
    value: "Red 2",
  },
  {
    key: "#AB0410",
    value: "Red 3",
  },
  {
    key: "#BB2C53",
    value: "Red 4",
  },
  {
    key: "#5F46C5",
    value: "Purple",
  },
  {
    key: "#666B70",
    value: "Monochrome",
  },
];

export const colorNamesSelect = [
  "Бело-голубые",
  "Бело-красные",
  "Бело-синие",
  "Бордовые",
  "Голубые",
  "Желто-зеленые",
  "Желто-красные",
  "Желто-синие",
  "Желто-черные",
  "Зеленые",
  "Зелено-белые",
  "Зелено-желтый",
  "Зелено-красные",
  "Зелено-синие",
  "Красные",
  "Красно-белые",
  "Красно-желтые",
  "Красно-зеленые",
  "Красно-синие",
  "Красно-черные",
  "Небесные",
  "Небесно-бордовые",
  "Оранжевые",
  "Синие",
  "Сине-белые",
  "Сине-голубые",
  "Сине-желтые",
  "Сине-черные",
  "Темно-синие",
  "Фиолетовые",
  "Черно-белые",
  "Черно-желтые",
  "Черно-зеленые",
  "Черно-синие",
];
