import { Show, useForm } from "@refinedev/antd";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { v4 as randomId } from "uuid";

import { errorNotification, successNotification } from "../../../helpers";
import {
  AppImageUploader,
  AppRichText,
  AppTitle,
  EFileTypes,
  richTextTemplate,
} from "../../../components";
import { LeagueStaffType } from "../Lib";
import { noSpecialCharValidator } from "../../../validators";
import { useNavigation } from "@refinedev/core";

export const SocialCreate = () => {
  const { push } = useNavigation();

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<LeagueStaffType>({
      resource: "social-responsibility",
      action: "create",
      meta: {},
      dataProviderName: "socialResponsibilityDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Сотрудник добавлен",
          type: "success",
        },
        callback: () => push("/league-info/social-responsibility"),
      }),
      ...errorNotification({}),
    });

  return (
    <Show
      title={<AppTitle canGoBack>Добавление проекта</AppTitle>}
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={"icon"}
              rules={[
                {
                  required: true,
                  message: "Загрузите иконку",
                },
              ]}
            >
              <AppImageUploader
                type="PARTNERS"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{
                  modalTitle: "Редактировать иконку",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Иконка",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"title"}
              label={"Название:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо название",
                },
                {
                  max: 50,
                  min: 3,
                  message: "Не более 50 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите название"}></Input>
            </Form.Item>
            <Form.Item
              name={"url"}
              label={"Сайт партнера:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать сайт партнера",
                },
              ]}
            >
              <Input placeholder={"Укажите сайт партнера"}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"description"} label={"Описание:"}>
              <AppRichText
                editorTemplate={richTextTemplate.backofficeNews}
                richTextProps={{
                  placeholder: "Укажите описание",
                  setOptions: {
                    buttonList: richTextTemplate.backofficeNews,
                    font: ["TT Norms Pro", "TT Runs"],
                    colorList: ["#FFFFFF", "#F6F6F6", "#9EA8B4", "#5D636A"],
                    linkProtocol: "https://",
                    linkRelDefault: { default: "no-follow" },
                    defaultStyle:
                      "font-family: TT Norms Pro, sans-serif; background-color: #383838; color: #FFFFFF; font-size: 16px;",
                    fontSize: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                  },
                }}
              />
            </Form.Item>
          </Col>
          <Space>
            <Button type={"primary"} htmlType={"submit"}>
              {"Добавить проект"}
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
