import { FC } from "react";
import { useModal } from "@refinedev/antd";
import { useDelete, useNavigation } from "@refinedev/core";
import { errorNotification, successNotification } from "../../../helpers";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

type NewsDeleteProps = {
  id: number;
  name: string;
  leagueName: string;
};

export const LinksDelete: FC<NewsDeleteProps> = ({ id, name, leagueName }) => {
  const { modalProps, show, close } = useModal();
  const { mutate: deleteMutate } = useDelete();
  const { push } = useNavigation();

  const handleDelete = () => {
    deleteMutate({
      dataProviderName: "whereToLookDataProvider",
      resource: "where-to-look",
      id: "",
      meta: {
        id,
      },
      ...successNotification({
        successData: {
          message: `Ресурс «${name}» успешно удален`,
          type: "success",
        },
        callback: () => {
          close();
          push(`/where-to-look/${leagueName}`);
        },
      }),
      ...errorNotification({}),
    });
  };

  return (
    <>
      <Button
        size={"small"}
        danger
        onClick={show}
        block
        icon={<DeleteOutlined />}
      >
        Удалить
      </Button>

      <Modal
        okText={"Удалить"}
        cancelText={"Отмена"}
        onOk={handleDelete}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        Вы уверены что хотите удалить ресурс «{name}»?
      </Modal>
    </>
  );
};
