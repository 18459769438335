import { FC, useState } from "react";
import { Card, Space, Modal } from "antd";
import { DeleteOutlined, SelectOutlined } from "@ant-design/icons";
import { useDelete } from "@refinedev/core";
import { useModal } from "@refinedev/antd";

import {
  AppSimpleLink,
  VkIcon,
  YouTubeIcon,
  TelegramIcon,
} from "../../../../../components";
import { errorNotification, successNotification } from "../../../../../helpers";

import s from "./Clubs-Social-Links.module.scss";

export const ClubsSocialLinks: FC<any> = ({
  vk,
  yt,
  tg,
  leagueId,
  teamId,
  onRefetchCard,
}) => {
  if (!vk && !yt) {
    return null;
  }

  const [linkType, setLinkType] = useState("");

  const { modalProps, show, close } = useModal();

  const { mutate: deleteMutate } = useDelete();

  const handleDelete = () => {
    deleteMutate({
      dataProviderName: "socialLinksDataProvider",
      resource: "socialLinks",
      id: "",
      meta: {
        teamId: Number(teamId),
        leagueId: Number(leagueId),
        linkType,
      },
      ...successNotification({
        successData: {
          message: "Ссылка удалена",
          type: "success",
        },
        callback: () => {
          close();
          setLinkType("");
          onRefetchCard();
        },
      }),
      ...errorNotification({}),
    });
  };

  return (
    <>
      <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
        <Space direction={"horizontal"} size={32} style={{ width: "100%" }}>
          {vk && (
            <Card
              className={s.card}
              actions={[
                <AppSimpleLink link={vk} key="open">
                  <SelectOutlined />
                </AppSimpleLink>,
                <DeleteOutlined
                  key="delete"
                  onClick={() => {
                    setLinkType("VK");
                    show();
                  }}
                />,
              ]}
            >
              <VkIcon />
            </Card>
          )}
          {tg && (
            <Card
              className={s.card}
              actions={[
                <AppSimpleLink link={tg} key="open">
                  <SelectOutlined />
                </AppSimpleLink>,
                <DeleteOutlined
                  key="delete"
                  onClick={() => {
                    setLinkType("TELEGRAM");
                    show();
                  }}
                />,
              ]}
            >
              <TelegramIcon />
            </Card>
          )}
          {yt && (
            <Card
              className={s.card}
              actions={[
                <AppSimpleLink link={yt} key="open">
                  <SelectOutlined />
                </AppSimpleLink>,
                <DeleteOutlined
                  key="delete"
                  onClick={() => {
                    setLinkType("YOUTUBE");
                    show();
                  }}
                />,
              ]}
            >
              <YouTubeIcon />
            </Card>
          )}
        </Space>
      </Space>
      <Modal
        title={"Вы уверены, что хотите удалить ссылку?"}
        onOk={handleDelete}
        onCancel={close}
        {...modalProps}
        cancelText={"Отмена"}
        okText={"Удалить ссылку"}
      ></Modal>
    </>
  );
};
