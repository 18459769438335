import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { LeagueDeptType } from "../Lib";
import { getAllDepts, recursiveUprateOrd } from "../Lib/helpers";

export const departmentsTeamDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const url = "cms/league/dept";

      try {
        const { data } = await httpClient.get(url, {
          params: { departmentType: "TEAM" },
        });

        const result = [];

        return {
          data: getAllDepts(data?.childDept || []),
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { id } = meta as { id: number };

      const url = "cms/league/dept/item";

      try {
        const { data } = await httpClient.get(url, { params: { deptId: id } });
        // const oneResource = data.find(
        //   (item: LeagueStaffType) => item.deptId == id
        // );
        return {
          data: data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      meta: {
        deptsData: LeagueDeptType[];
      };
      variables: any;
    }) => {
      const { ord, parentId, ...otherVariables } = variables;
      const { deptsData } = meta;
      const url = "cms/league/dept";

      try {
        await httpClient.post(url, {
          ...otherVariables,
          ord: Number(ord),
          parentId: -2,
        });
        await recursiveUprateOrd(
          variables.ord,
          deptsData,
          async (data) => await httpClient.put(url, data)
        );
        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
        deptsData: LeagueDeptType[];
      };
      variables: any;
    }) => {
      const url = "cms/league/dept";

      const { id, deptsData } = meta;

      const currentIndex = deptsData.findIndex((dept) => dept.id == id);
      if (currentIndex) deptsData.splice(currentIndex, 1);

      try {
        const { data } = await httpClient.put(url, {
          ...variables,
          id,
        });
        await recursiveUprateOrd(
          variables.ord,
          deptsData,
          async (data) => await httpClient.put(url, data)
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/dept";
      try {
        const { data } = await httpClient.delete(url, {
          params: { deptId: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
