import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const clubsTrophiesDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({ resource, id, variables, meta }: any) => {
      const updateUrl = "/cms/team/trophy";

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          trophy: [String(variables.trophy)],
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id, meta }: any) => {
      const url = "/cms/team/trophy/delete";
      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({
            leagueId: meta.leagueId,
            teamId: meta.teamId,
          })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
