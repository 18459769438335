import {
  ShowButton,
  useTable,
  List,
  CreateButton,
  TextField,
} from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import { Space, Table } from "antd";

import { LeagueStaffType } from "../../Lib";
import { AppImagePreview } from "components";
import { LinksDelete } from "./LinksDelete";

export function FNLPresidium() {
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<LeagueStaffType[]>({
    dataProviderName: "employeesPresidiumDataProvider",
    resource: "employees",
    meta: {},
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const handleNavigateToCreate = () => {
    push("/league-info/employees/presidium/create");
  };

  const handleNavigateToEdit = (id: string) => {
    push(`/league-info/employees/presidium/show/${id}`);
  };

  return (
    <List
      title={"Президиум ФНЛ"}
      resource={"employees"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton
          resource={"employees"}
          onClick={() => handleNavigateToCreate()}
        >
          Добавить сотрудника
        </CreateButton>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Ссылки отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<LeagueStaffType>
          dataIndex={""}
          width={150}
          key="staffPhoto"
          title={"Превью фото"}
          render={(value) => {
            return <AppImagePreview imageURL={value.staffPhoto} useS3={true} />;
          }}
        />
        <Table.Column<LeagueStaffType>
          width={250}
          dataIndex={"staffName"}
          key="staffName"
          title="ФИО сотрудника"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueStaffType>
          dataIndex={"jobTitle"}
          key="jobTitle"
          title="Должность"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueStaffType>
          width={150}
          dataIndex={"birthday"}
          key="birthday"
          title="Дата рождения"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueStaffType>
          width={200}
          dataIndex={"teamDay"}
          key="teamDay"
          title="Присоединился к ФНЛ"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueStaffType>
          width={250}
          dataIndex={"name"}
          fixed={"right"}
          key="show"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                onClick={() => handleNavigateToEdit(String(record.staffId))}
                size={"small"}
                block
              >
                Смотреть / Редактировать
              </ShowButton>
              <LinksDelete
                id={Number(record.staffId)}
                name={record.staffName}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
