import { TextField, useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../helpers";
import { FC } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import { streamValidator } from "../../../../validators";

type selectType = { key: string; value: string };

type MatchesTranslationAddProps = {
  matchId: number;
  leagueId: number;
  onRefetchCard: () => void;
  intersectionSelectOption: selectType[];
};
export const MatchesTranslationAdd: FC<MatchesTranslationAddProps> = ({
  matchId,
  leagueId,
  onRefetchCard,
  intersectionSelectOption,
}) => {
  const { modalProps, show, close } = useModal();

  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "create",
    resource: "translations",
    meta: {
      matchId,
      leagueId,
    },
    dataProviderName: "translationsDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Медиа блок добавлен",
        type: "success",
      },
      callback: () => {
        close();
        form.resetFields();
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const typeLink = useWatch("typeLink", form);

  console.log(typeLink);

  const renderText = () => {
    switch (typeLink) {
      case "vk":
        return `ВК. \n
        Для добавления ссылки необходимо авторизироваться в ВК. Ссылка добавляется на ролик через кнопку Share – Export. Общий формат ссылки в вк <iframe src="https://vk.com/video_ext.php?oid=-29484355&id=456247241&hd=2" width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>. Добавляется только выделенная часть https://vk.com/video_ext.php?oid=-29484355&id=456247241&hd=2.`;
      case "match-tv":
        return `Матч ТВ.
        Должна начинаться с video.matchtv.ru`;
      case "match-premier":
        return `Матч премьер. Должна начинаться
        с matchpremier.ru`;
      case "kinopoisk":
        return `Кинопоиск. Должна начинаться с (https://)
        kinopoisk.ru или (https://) hd.kinopoisk.ru`;
      case "tricolor":
        return "Триколор. Должна начинаться с tricolor.ru или tricolor.tv";
      default:
        return "";
    }
  };

  return (
    <>
      <Button onClick={show} icon={<PlusOutlined />}>
        Добавить трансляцию
      </Button>
      <Modal
        title={"Добавление трансляции"}
        onCancel={close}
        {...modalProps}
        footer={<></>}
      >
        <Form
          style={{
            width: "100%",
          }}
          {...formProps}
          layout={"vertical"}
        >
          <Form.Item
            name={"typeLink"}
            label={"Тип трансляции:"}
            rules={[
              {
                required: true,
                message: "Необходимо выбрать тип трансляции",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Выберите тип трансляции"
              notFoundContent={"Трансляция не найдена"}
            >
              {intersectionSelectOption.map((translation) => {
                return (
                  <Select.Option key={translation.key} value={translation.key}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <TextField value={translation.value} />
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={"url"}
            label={"Ссылка на трансляцию"}
            rules={[
              {
                required: true,
                message: "Необходимо указать ссылку",
              },
              streamValidator(typeLink),
            ]}
          >
            <Input placeholder={"Укажите ссылку на трансляцию"}></Input>
          </Form.Item>

          <Button type={"primary"} htmlType={"submit"}>
            Добавить трансляцию
          </Button>
          <div style={{ padding: "16px 0", color: "grey" }}>{renderText()}</div>
        </Form>
      </Modal>
    </>
  );
};
