import { Show, useForm } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { useMatch } from "react-router-dom";
import { v4 as randomId } from "uuid";

import { errorNotification, successNotification } from "../../../../helpers";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../../components";
import { LeagueDeptType } from "../../Lib";
import { noSpecialCharValidator } from "../../../../validators";

export const DepartmentsShow = () => {
  const route = useMatch("/league-info/employees/team/show-dept/:id");
  const id = Number(route?.params.id);

  const { data, isLoading, refetch } = useList<LeagueDeptType[]>({
    resource: "employees",
    meta: {},
    dataProviderName: "departmentsTeamDataProvider",
    ...errorNotification(),
  });

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<LeagueDeptType>({
      resource: "employees",
      action: "edit",
      id,
      meta: {
        id: id,
        deptsData: data?.data,
      },
      dataProviderName: "departmentsTeamDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: "Ресурс изменен",
          type: "success",
        },
      }),
      ...errorNotification({}),
    });

  return (
    <Show
      title={<AppTitle canGoBack>Редактирование департамента</AppTitle>}
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={"icon"}>
              <AppImageUploader
                type="STAFF"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{
                  modalTitle: "Редактировать иконку",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Иконка",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"title"}
              label={"Наименование департамента:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо наименование департамента ",
                },
                {
                  max: 100,
                  min: 3,
                  message: "Не более 100 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите наименование департамента "}></Input>
            </Form.Item>
            <Form.Item
              name={"ord"}
              label={"Порядок отображения департамента"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message:
                    "Необходимо указать порядок отображения департамента",
                },
              ]}
            >
              <Input
                placeholder={"Укажите порядок отображения департамента"}
                type="number"
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"description"}
              label={"Описание:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать описание",
                },
                {
                  min: 5,
                  max: 1000,
                  message: "Описание может быть от 5 до 1000 символов",
                },
              ]}
            >
              <Input.TextArea
                rows={10}
                placeholder={"Укажите описание"}
              ></Input.TextArea>
            </Form.Item>
          </Col>
          <Space>
            <Button type={"primary"} htmlType={"submit"}>
              {"Отредактировать"}
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
