import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const clubsStadiumDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      meta,
    }: {
      resource: string;
      meta: {
        leagueId: number;
      };
    }) => {
      const { leagueId } = meta;
      try {
        const { data } = await httpClient.get("/cms/stadium", {
          params: { leagueId },
        });
        return {
          data: data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      variables,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      variables: { color: string; leagueId: number; teamId: number };
      meta: {
        teamId: number;
        leagueId: number;
      };
    }) => {
      const updateUrl = "/cms/stadium/team";
      const { leagueId, teamId } = meta;

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          leagueId,
          teamId,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      id: number;
      resource: string;
      meta: { leagueId: number; teamId: number };
    }) => {
      const url = "/cms/stadium/team";
      const { leagueId, teamId } = meta;
      try {
        const { data } = await httpClient.delete(`${url}`, {
          params: { leagueId, teamId },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
