import s from "../Clubs-Main-Info/Clubs-Main-Info.module.scss";
import {
  AppImagePreview,
  AppImageUploader,
  AppViewCard,
  EFileTypes,
} from "../../../../../components";
import { FC, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import { useForm, useModal } from "@refinedev/antd";
import { Button, Form, Modal, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { errorNotification, successNotification } from "../../../../../helpers";
import { v4 as randomId } from "uuid";
import { useDelete } from "@refinedev/core";

type ClubsStadiumPhotoType = {
  url: string;
  leagueId: number;
  stadiumId: number;
  onRefetchCard: () => void;
};
export const ClubsStadiumPhoto: FC<ClubsStadiumPhotoType> = ({
  stadiumId,
  leagueId,
  url,
  onRefetchCard,
}) => {
  const { modalProps, show, close } = useModal();
  const { mutate } = useDelete();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    resource: "clubs",
    dataProviderName: "clubsPhotoStadiumDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Фото стадиона добавлено",
        type: "success",
      },
      callback: () => {
        close();
        onRefetchCard();
      },
    }),
    ...errorNotification({
      errorData: {
        errorKeys: [
          {
            errorCode: "400",
            message: "Ошибка при добавлении фото стадиона",
          },
        ],
      },
    }),
  });

  const openEdit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    show();
  };

  const onDeleteBackground = () => {
    mutate({
      resource: "clubs",
      dataProviderName: "clubsPhotoStadiumDataProvider",
      id: "",
      ...successNotification({
        successData: {
          message: "Фото стадиона удалено",
          type: "success",
        },
        callback: () => {
          close();
          onRefetchCard();
        },
      }),
      ...errorNotification({}),
      meta: {
        stadiumId,
        leagueId,
      },
    });
  };
  useEffect(() => {
    form.setFieldValue("background", url);
  }, [url]);

  return (
    <div className={s.previewContainer}>
      {stadiumId && (
        <div
          className={s.previewBgEdit}
          title={"Редактировать"}
          onClick={openEdit}
        >
          <EditOutlined style={{ color: "var(--danger)" }} />
        </div>
      )}
      <AppViewCard label={"Фото стадиона"} style={{ width: "200px" }}>
        {stadiumId ? (
          <AppImagePreview sharedStyles={"width:200px"} imageURL={url} useS3 />
        ) : (
          <span>
            Добавление фото стадиона доступно после выбора стадиона из списка
          </span>
        )}
      </AppViewCard>
      <Modal {...modalProps} footer={<></>} title={"Редактировать фото"}>
        <Form
          {...formProps}
          initialValues={{
            ...formProps.initialValues,
            stadiumId,
            leagueId,
            url,
          }}
          layout={"vertical"}
        >
          <Form.Item name={"stadiumId"} hidden />
          <Form.Item name={"leagueId"} hidden />
          <Form.Item
            name={"url"}
            label={
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                Фото стадиона{" "}
                <Tooltip title="Фото стадиона используется в Карточке клуба. Пропорции загружаемого изображения: 9*16.">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Укажите фото стадиона",
              },
            ]}
          >
            <AppImageUploader
              entityId={`${randomId()}`}
              type="STADIUM"
              serviceType={EFileTypes.CLUBS_BG}
              cropperProps={{
                modalTitle: "Редактировать изображение",
              }}
              uploadProps={{
                multiple: false,
                children: "Изображение",
                listType: "picture-card",
              }}
            />
          </Form.Item>
          <Space className={s.modalFooter}>
            <Button type={"primary"} htmlType={"submit"}>
              Добавить фото стадиона
            </Button>
            {url && (
              <Button
                type={"primary"}
                danger
                htmlType={"button"}
                onClick={onDeleteBackground}
              >
                Удалить фото стадиона
              </Button>
            )}
          </Space>
        </Form>
      </Modal>
    </div>
  );
};
