export const nameLeagueMapByIndex: { [key in string]: string } = {
  ["100"]: "Первая лига",
  ["200"]: "Вторая лига A",
  ["300"]: "Вторая лига Б",
};

export const documentType = [
  { value: "FNL", text: "Документы ФНЛ" },
  { value: "RFS", text: "Документы РФС" },
  { value: "MELBET", text: "Документы МЕЛБЕТ 1-я лигa" },
  { value: "LEON_A", text: "Документы LEON 2-я лига A" },
  { value: "LEON_B", text: "Документы LEON 2-я лига Б" },
  // { value: "CUP", text: "Кубок" },
];
