import { EditButton, Show, useForm } from "@refinedev/antd";
import {
  errorNotification,
  s3Path,
  successNotification,
} from "../../../helpers";

import { Col, Form, Input, Row, Space } from "antd";
import { AppTitle } from "../../../components";
import { PhilosophyCreateRequest } from "../Lib/types";
import { useWatch } from "antd/es/form/Form";
import { useContext } from "react";
import { useNavigation } from "@refinedev/core";
import { EnvContext } from "providers";

export const PartnerShow = () => {
  const { push } = useNavigation();
  const { THUMBOR_URL } = useContext(EnvContext);

  const { formProps, saveButtonProps, form, formLoading } =
    useForm<PhilosophyCreateRequest>({
      resource: "partner",
      action: "edit",
      id: "",
      dataProviderName: "partnerDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Размещение успешно создано",
          type: "success",
        },
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    });

  const watchFile = useWatch("file", form);

  return (
    <Show
      isLoading={formLoading}
      title={<AppTitle isLoading={formLoading}>Философия</AppTitle>}
      breadcrumb={false}
      headerButtons={
        <Space direction={"horizontal"}>
          <EditButton onClick={() => push("/league-info/partner/edit")}>
            Редактировать
          </EditButton>
        </Space>
      }
      footerButtons={null}
      goBack={false}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
        }}
        layout={"vertical"}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={"title"} label={"Заголовок"}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={"phone"}
              label={"Телефон:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name={"email"}
              label={"Email:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item name={"fax"} label={"Факс:"} style={{ width: "100%" }}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"file"}
              label={"Файл:"}
              style={{ maxWidth: "300px", width: "100%" }}
            >
              <a
                href={s3Path({
                  path: watchFile,
                  original: true,
                  thumbor: THUMBOR_URL,
                })}
                target="_blank"
                rel="noreferrer"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              >
                {watchFile?.replace(/.*\//, "")}
              </a>
            </Form.Item>
            <Form.Item label="Текст" name={"content"}>
              <Input.TextArea
                disabled
                rows={10}
                placeholder={"Укажите контент"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Show>
  );
};
