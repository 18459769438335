import { Outlet, Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { ListLinks } from "../components/ListLinks";
import { ListLinksCreate } from "../components/ListLinksCreate";

const whereToLookRoutes = [
  <Route key={"where-to-look"} path="where-to-look" element={<Outlet />}>
    <Route key={"first-league"} path="first-league">
      <Route
        index
        element={
          <ShowProvider>
            <ListLinks />
          </ShowProvider>
        }
      />
    </Route>

    <Route key={"second-league"} path="second-league">
      <Route
        index
        element={
          <ShowProvider>
            <ListLinks />
          </ShowProvider>
        }
      />
    </Route>

    <Route key={"third-league"} path="third-league">
      <Route
        index
        element={
          <ShowProvider>
            <ListLinks />
          </ShowProvider>
        }
      />
    </Route>
  </Route>,
  <Route
    key={"support-message-create"}
    path="/where-to-look/:type/create"
    element={
      <ShowProvider>
        <ListLinksCreate />
      </ShowProvider>
    }
  />,
];

export const WHERE_TO_LOOK_ROUTES = whereToLookRoutes.map((route) => route);
