import React, { useState, useEffect, useRef } from "react";
import { Show, useForm } from "@refinedev/antd";
import { useCreate } from "@refinedev/core";
import { Button, Spin, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";

import { successNotification } from "../../../helpers";

import s from "./Users-Import-Content.module.scss";

const props: UploadProps = {
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
  defaultFileList: [
    {
      uid: "1",
      name: "xxx.png",
      status: "uploading",
      url: "http://www.baidu.com/xxx.png",
      percent: 33,
    },
  ],
};

export const UsersImportContent = () => {
  const [file, setFile] = useState(null) as any;
  const [dowloadText, setDownloadText] = useState<string>("");
  const formData = new FormData();

  useEffect(() => {
    formData.append("file", file);
  }, [file]);

  const { formProps, form } = useForm<{
    url: string;
  }>({});

  const { mutate, isLoading } = useCreate();

  const upload = () => {
    mutate({
      resource: "usersImport",
      dataProviderName: "usersImportDataProvider",
      meta: {
        formData,
      },
      values: {},
      ...successNotification({
        successData: {
          message: "Файл обработан",
          type: "success",
        },
        callback: (data) => {
          setDownloadText(data.data);
          form.resetFields();
        },
      }),
      errorNotification: (data, values, resource) => {
        return {
          message: "Что-то пошло не так, загрузите другой файл",
          description: "Error",
          type: "error",
        };
      },
    });
  };

  const downloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([dowloadText], { type: "text/csv;charset=utf-8;" });
    element.href = URL.createObjectURL(file);
    element.download = "data.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleFileChange = (e: any) => {
    setDownloadText("");
    setFile(e.target.files[0]);
  };

  const fileInputRef = useRef(null);

  const handleClick = () => {
    // @ts-ignore: Object is possibly 'null'.
    fileInputRef.current.click();
  };

  return (
    <Show
      contentProps={{ style: { height: 500 } }}
      title="Импорт пользователей"
      resource={"usersimport"}
      breadcrumb={false}
      headerButtons={<></>}
    >
      <Spin spinning={isLoading}>
        <Form
          style={{
            width: "100%",
          }}
          {...formProps}
          layout={"vertical"}
          onFinish={upload}
        >
          <Form.Item
            name={"file"}
            rules={[
              {
                required: true,
                message: "Необходимо добавить файл",
              },
            ]}
          >
            <div className={s.addFile}>
              <input
                type="file"
                ref={fileInputRef}
                className={s.inputEl}
                onChange={handleFileChange}
              />
              <Button onClick={handleClick}>
                {file ? file?.name : "Загрузить файл"}
              </Button>
              <div
                style={{
                  marginTop: "12px",
                }}
              >
                Файл должен быть формата TXT. Максимально разрешенный размер
                файла 10Мб
              </div>
            </div>
          </Form.Item>
          {file && file?.type !== "text/plain" && (
            <div style={{ color: "red", marginBottom: 16 }}>
              Неверный формат файла
            </div>
          )}
          {file &&
            file?.size > 10485760 && ( //max 10Мб
              <div style={{ color: "red", marginBottom: 16 }}>
                Файл слишком большой
              </div>
            )}
          <div style={{ display: "flex" }}>
            <Button
              type={"primary"}
              htmlType={"submit"}
              disabled={
                !file || file?.type !== "text/plain" || file?.size > 10485760
              }
              style={{ marginRight: "16px" }}
            >
              Импорт
            </Button>
            <Button
              type={"primary"}
              icon={<UploadOutlined />}
              onClick={downloadFile}
              disabled={!dowloadText}
            >
              Скачать результат
            </Button>
          </div>
        </Form>
      </Spin>
    </Show>
  );
};
