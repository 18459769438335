import axios from "axios";
import { HttpError } from "@refinedev/core";

const axAPI = axios.create({
  withCredentials: false,
});

axAPI.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("auth");
    const isRefreshToken = config.url.includes("refresh");
    if (token && !isRefreshToken) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    } else {
      config.headers = {};
    }

    return config;
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      return Promise.reject({ message: "Отсутствует подключение к серверу" });
    }

    return Promise.reject(error as any);
  }
);

axAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data,
      statusCode: error.response?.status,
      errorBody: error.response.data,
    };

    if (error.response && error.response.status === 401) {
      const refreshToken = localStorage.getItem("refresh_token");
      try {
        const refreshResponse = await axios.post(
          `${error.config.baseURL}/authorization/token/refresh`,
          {
            refreshToken: refreshToken,
          }
        );
        const { access_token, refresh_token } = refreshResponse.data;
        localStorage.setItem("auth", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        error.config.headers.Authorization = `Bearer ${access_token}`;
        return axios(error.config);
      } catch (tokenError) {
        localStorage.removeItem("auth");
        localStorage.removeItem("refresh_token");
        return Promise.reject(customError);
      }
    } else {
      return Promise.reject(customError);
    }
  }
);

export default axAPI;
