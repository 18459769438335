import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const socialResponsibilityDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const url = "cms/league/social";

      try {
        const { data } = await httpClient.get(url);

        return {
          data: data.projects,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { id } = meta as { id: number };

      const url = "cms/league/social/project";

      try {
        const { data } = await httpClient.get(url, { params: { id: id } });
        return {
          data: data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      variables,
    }: {
      resource: string;
      variables: any;
    }) => {
      try {
        await httpClient.post("cms/league/social", {
          ...variables,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
      variables: any;
    }) => {
      const url = "cms/league/social";

      const { id } = meta;

      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          id,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/social/project";
      try {
        const { data } = await httpClient.delete(url, {
          params: { id: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
