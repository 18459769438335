import { Create, useForm } from "@refinedev/antd";
import {
  dateFormat,
  DayMonthYearHoursAndMinutes,
  errorNotification,
  hoursAndMinutes,
  successNotification,
} from "../../../helpers";

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../components";
import { v4 as randomId } from "uuid";
import { NewsBlockCreate } from "../News-Create/News-Block-Create";
import { NewCreateHashTags } from "../News-Create/New-Create-Hash-Tags";
import { useMatch } from "react-router-dom";
import { NewsCreateRequest } from "../Lib/types";
import { NewsDelete } from "../News-Delete/News-Delete";
import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";
import dayjs from "dayjs";

export const NewsEdit = () => {
  const route = useMatch("/:parent/:action/:id");
  const id = route?.params.id;

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<NewsCreateRequest>({
      resource: "news",
      action: "edit",
      id: Number(id),
      dataProviderName: "newsDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: "Новость успешно отредактирована",
          type: "success",
        },
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    });

  const watchReleaseDate = useWatch("newsDate", form);
  const isPublic = useWatch("isPublic", form);

  const renderDate = useMemo(() => {
    if (watchReleaseDate === "Invalid Date") {
      return dateFormat();
    }
    return dateFormat(watchReleaseDate, DayMonthYearHoursAndMinutes);
  }, [watchReleaseDate]);

  const onSubmitForm = (values: any) => {
    onFinish({ ...values });
  };

  return (
    <Create
      isLoading={formLoading}
      title={
        <AppTitle isLoading={formLoading} canGoBack routerLink={"/news"}>
          Редактирование новости
          <Tag color={!formProps.initialValues?.isPublic ? "orange" : "blue"}>
            {!formProps.initialValues?.isPublic ? "Черновик" : "Публикация"}
          </Tag>
        </AppTitle>
      }
      breadcrumb={false}
      headerButtons={
        <Space direction={"horizontal"}>
          <NewsDelete
            type={"page"}
            id={formProps.initialValues?.id}
            title={formProps.initialValues?.title}
          />
        </Space>
      }
      footerButtons={<></>}
      goBack={false}
    >
      <Form
        {...formProps}
        onFinish={onSubmitForm}
        initialValues={{
          newsDate: dayjs(),
          ...formProps.initialValues,
          leagueId:
            formProps.initialValues?.leagueId === null
              ? 0
              : formProps.initialValues?.leagueId,
        }}
        layout={"vertical"}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Space size={16} direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                name={"mainPhotoUrl"}
                label={"Изображение новости"}
                rules={
                  isPublic && [
                    {
                      required: true,
                      message: "Укажите изображение",
                    },
                  ]
                }
              >
                <AppImageUploader
                  type="NEWS"
                  entityId={`${randomId()}`}
                  serviceType={EFileTypes.NEWS}
                  cropperProps={{
                    modalTitle: "Редактировать изображение",
                  }}
                  uploadProps={{
                    multiple: false,
                    children: "Изображение новости",
                    listType: "picture-card",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Заголовок"
                rules={[
                  { required: true, message: "Укажите заголовок" },
                  {
                    min: 5,
                    max: 100,
                    message: "Заголовок может быть от 5 до 100 символов",
                  },
                ]}
                name={"title"}
              >
                <Input.TextArea rows={2} placeholder={"Укажите заголовок"} />
              </Form.Item>
              <Form.Item
                label="Превью новости"
                rules={[
                  // { required: true, message: "Укажите превью" },
                  {
                    min: 5,
                    max: 200,
                    message: "Описание может быть от 5 до 200 символов",
                  },
                ]}
                name={"preview"}
              >
                <Input.TextArea rows={2} placeholder={"Укажите заголовок"} />
              </Form.Item>
              {/*isPublic*/}
              <NewsBlockCreate form={form} isPublic={true} />
              <Form.Item
                name="leagueId"
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Лига{" "}
                    <Tooltip
                      title={
                        'Если в поле "Лига" указать "Все" новость будет опубликована на все лиги и придет уведомление о новости лиги'
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                rules={
                  // isPublic
                  [{ required: true, message: "Укажите лигу" }]
                }
              >
                <Select showArrow={true} placeholder="Выберите лигу">
                  <Select.Option key={"100"} value={100}>
                    Первая лига
                  </Select.Option>
                  <Select.Option key={"200"} value={200}>
                    Вторая лига А
                  </Select.Option>
                  <Select.Option key={"300"} value={300}>
                    Вторая лига Б
                  </Select.Option>
                  <Select.Option key={"0"} value={0}>
                    Все
                  </Select.Option>
                </Select>
              </Form.Item>
              <NewCreateHashTags />
              <Form.Item
                name={"newsDate"}
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Дата публикации{" "}
                    <Tooltip
                      title={
                        "Если дата публикации указывается в будущем времени, то новость не отобразится до указанной даты"
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
              >
                <DatePicker
                  allowClear={false}
                  format={DayMonthYearHoursAndMinutes}
                  showTime={{ format: hoursAndMinutes }}
                  style={{ width: "100%" }}
                  placeholder={"Укажите дату"}
                />
              </Form.Item>

              <Space
                direction={"horizontal"}
                align={"baseline"}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item name={"showDate"} valuePropName="checked">
                  <Checkbox>Отображать дату публикации</Checkbox>
                </Form.Item>
                <div>Дата публикации: {renderDate}</div>
              </Space>
              <Form.Item
                name={"isPublic"}
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Отредактировать как:{" "}
                    <Tooltip
                      title={
                        "При сохранении новости как Черновик, она не будет опубликована"
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
              >
                <Radio.Group>
                  <Radio value={false}>Черновик</Radio>
                  <Radio value={true}>Публикацию</Radio>
                </Radio.Group>
              </Form.Item>
              <Space>
                <Button type={"primary"} htmlType={"submit"}>
                  Отредактировать
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
