import { Show, useForm } from "@refinedev/antd";
import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { AppTitle } from "../../../components";
import { errorNotification, successNotification } from "../../../helpers";

type LeaguesLiesRecordType = {
  id: number;
  name: string;
};
export const LeaguesList = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "edit",
    resource: "league-info",
    id: "",
    dataProviderName: "leagueInfoDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Информация о лиге изменена",
        type: "success",
      },
    }),
    ...errorNotification({}),
  });

  return (
    <Show
      headerButtons={<></>}
      goBack={false}
      breadcrumb={false}
      title={
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <AppTitle canGoBack>О лиге</AppTitle>
          <Tooltip
            title={
              'Добавление описания лиги ФНЛ. Попадает в раздел МП "Еще" → "О лиге"'
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      }
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form
            style={{
              width: "100%",
            }}
            {...formProps}
            layout={"vertical"}
          >
            <Form.Item
              name={"description"}
              label={"Описание лиги:"}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать описание лиги",
                },
                { min: 5, message: "Минимальное количество - 5" },
                { max: 1000, message: "Не более 1000 символов" },
              ]}
            >
              <Input.TextArea rows={10} placeholder={"Укажите описание лиги"} />
            </Form.Item>
            <Button type={"primary"} htmlType={"submit"}>
              Обновить информацию
            </Button>
          </Form>
        </Col>
      </Row>
    </Show>
  );
};
