import { TextField, useForm, useModal } from "@refinedev/antd";
import { FC } from "react";
import { errorNotification, successNotification } from "../../../../../helpers";
import { Button, Form, Input, Modal, Space } from "antd";
import s from "./Clubs-Change-Trophies.module.scss";
import { EditOutlined } from "@ant-design/icons";
import { ClubsDeleteEntity } from "../Clubs-Delete-Entity/Clubs-Delete-Entity";
import { AppViewCard } from "../../../../../components";

type ClubsChangeTrophiesNewProps = {
  leagueId: number;
  teamId: number;
  trophy: string[];
  onRefetchCard: () => void;
};
export const ClubsChangeTrophies: FC<ClubsChangeTrophiesNewProps> = ({
  teamId,
  leagueId,
  trophy,
  onRefetchCard,
}) => {
  const { modalProps, show, close } = useModal();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "edit",
    resource: "trophy",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "clubsTrophiesDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Трофеи изменены",
        type: "success",
      },
      callback: () => {
        close();
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const handleRefetchOnDelete = () => {
    onRefetchCard();
    form.setFieldValue("trophy", null);
  };

  return (
    <>
      <Modal
        title={"Редактирование трофеев"}
        onCancel={close}
        {...modalProps}
        footer={<></>}
      >
        <Form
          {...formProps}
          initialValues={{
            teamId,
            leagueId,
            trophy,
          }}
          layout={"horizontal"}
        >
          <Form.Item name={"teamId"} hidden />
          <Form.Item name={"leagueId"} hidden />

          <Form.Item
            name="trophy"
            label={"Трофеи:"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Необходимо указать трофеи",
              },
              {
                min: 1,
                max: 10,
                message: "Трофеи могут быть от 1 до 10 символов",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Button type={"primary"} htmlType={"submit"}>
            Изменить трофеи
          </Button>
        </Form>
      </Modal>
      <AppViewCard label={"Трофеи:"}>
        <Space
          direction={"horizontal"}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          size={24}
        >
          <Space
            direction={"horizontal"}
            size={16}
            style={{
              width: "100%",
            }}
            wrap
          >
            {trophy?.length ? <TextField value={trophy[0]} /> : "–"}
          </Space>
          <div className={s.wrapContainer}>
            <EditOutlined
              style={{
                fontSize: 20,
                cursor: "pointer",
                color: "var(--accent-main)",
              }}
              onClick={show}
            />
            {!!trophy?.length && (
              <ClubsDeleteEntity
                onRefetch={handleRefetchOnDelete}
                deleteId={{ leagueId, teamId }}
                message={"Трофеи успешно удалены"}
                okText={"Удалить трофеи"}
                modalText={"Вы уверены, что хотите удалить трофеи?"}
                dataProviderName={"clubsTrophiesDataProvider"}
              />
            )}
          </div>
        </Space>
      </AppViewCard>
    </>
  );
};
