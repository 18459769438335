import { Show, useForm } from "@refinedev/antd";
import { Button, Flex, Form, Input, Select, Space, Upload } from "antd";

import { errorNotification, successNotification } from "../../../helpers";
import { AppTitle } from "../../../components";
import { documentType, LeagueStaffType } from "../Lib";
import { useNavigation } from "@refinedev/core";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import axAPI from "../../../api/axios-instance";

export const DocsCreate = () => {
  const { push } = useNavigation();

  const [fileList, setFileList] = useState<any[]>([]);
  const [fileUrl, setFileUrl] = useState<string>("");

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<LeagueStaffType>({
      resource: "docs",
      action: "create",
      meta: { fileUrl: fileUrl },
      dataProviderName: "docsDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Документ добавлен",
          type: "success",
        },
        callback: () => push("/league-info/docs"),
      }),
      ...errorNotification({}),
    });

  async function uploadFile(file: File, type: string) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const result = await axAPI.post("/cms/image/upload/type", formData, {
        params: { type },
      });
      return Promise.resolve({ s3id: result.data });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return (
    <Show
      title={<AppTitle canGoBack>Добавление документа</AppTitle>}
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Flex gap={30}>
          <Form.Item
            name={"icon"}
            style={{ maxWidth: "300px", width: "100%" }}
            rules={[
              {
                required: true,
                message: "Загрузите иконку",
              },
            ]}
          >
            <Upload
              customRequest={(e: any) => {
                uploadFile(e.file as File, "DOCUMENTS").then((res) => {
                  setFileUrl(res.s3id);
                  e.onSuccess("ok");
                });
              }}
              accept=".pdf"
              fileList={fileList}
              onChange={({ fileList }) => {
                setFileList(fileList);
                if (!fileList.length) setFileUrl("");
              }}
            >
              <Button disabled={!!fileList.length} icon={<UploadOutlined />}>
                Click to Upload
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name={"title"}
            label={"Название:"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Необходимо название",
              },
              {
                max: 200,
                min: 3,
                message: "Не более 200 символов и не менее 3 символов",
              },
            ]}
          >
            <Input placeholder={"Укажите название"}></Input>
          </Form.Item>
          <Form.Item
            name="type"
            label={"Раздел"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Необходимо указать раздел",
              },
            ]}
          >
            <Select placeholder="Выберите раздел">
              {documentType.map((elem) => (
                <Select.Option key={elem.value} value={elem.value}>
                  {elem.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Space>
            <Button type={"primary"} htmlType={"submit"} disabled={!fileUrl}>
              {"Добавить документ"}
            </Button>
          </Space>
        </Flex>
      </Form>
    </Show>
  );
};
