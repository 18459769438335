import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { ListLinkType } from "../Lib";

export const whereToLookDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const { leagueId } = meta as { leagueId: string };

      const url = "cms/watch/links";

      const query = {
        leagueId,
      };

      try {
        const { data } = await httpClient.get(url, { params: query });
        return {
          data: data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { leagueId, id } = meta as { leagueId: string; id: number };

      const url = "cms/watch/links";

      const query = {
        leagueId,
      };

      try {
        const { data } = await httpClient.get(url, { params: query });
        const oneResource = data.find((item: ListLinkType) => item.id === id);
        return {
          data: oneResource,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        leagueId: number;
        contentType: "BROADCAST" | "CONTENT";
      };
      variables: any;
    }) => {
      const url = "cms/watch/links/add";
      const { leagueId } = meta;
      try {
        await httpClient.post(url, {
          ...variables,
          leagueId,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = `cms/watch/links/delete?id=${meta.id}`;
      try {
        const { data } = await httpClient.post(url);
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
