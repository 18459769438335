import { Club } from "../../../Lib";
import { FC, ReactNode } from "react";
import { Col, Row, Space, Typography } from "antd";
import { AppImagePreview, AppViewCard } from "../../../../../components";
import { TextField } from "@refinedev/antd";
import { dateFormat, DayMonthYear } from "../../../../../helpers";
import s from "./Clubs-Main-Info.module.scss";
import { ClubsChangeColor } from "../Clubs-Change-Color/Clubs-Change-Color";
import { ClubsBackground } from "../Clubs-Background/Clubs-Background";
import { ClubsChangeStadium } from "../Clubs-Change-Stadium/Clubs-Change-Stadium";
import { ClubsStadiumPhoto } from "../Clubs-Stadium-Photo/Clubs-Stadium-Photo";

const { Title } = Typography;

type ClubsMainInfoProps = Pick<
  Club,
  | "title"
  | "logoUrl"
  | "bday"
  | "stadium"
  | "stadiumId"
  | "stadiumCity"
  | "stadiumPhoto"
  | "city"
  | "color"
  | "leagueId"
  | "teamId"
  | "colorName"
  | "background"
> & { children: ReactNode; onRefetch: () => void };
export const ClubsMainInfo: FC<ClubsMainInfoProps> = ({
  bday,
  logoUrl,
  stadium,
  stadiumCity,
  stadiumPhoto,
  stadiumId,
  title,
  city,
  color,
  colorName,
  children,
  leagueId,
  teamId,
  onRefetch,
  background,
}) => {
  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <Title level={5}>Основная информация</Title>
      <Row gutter={24}>
        <Col span={12}>
          <Space direction={"vertical"} style={{ width: "100%" }} size={24}>
            <AppViewCard label={"Название команды:"}>
              <TextField value={title} />
            </AppViewCard>
            <ClubsChangeColor
              color={{ value: color, id: null, colorName: colorName }}
              leagueId={leagueId}
              teamId={teamId}
              onRefetchCard={onRefetch}
            />
            <AppViewCard label={"Город клуба:"}>
              <TextField value={city} />
            </AppViewCard>
            <AppViewCard label={"Дата основания:"}>
              <TextField value={bday ? dateFormat(bday, DayMonthYear) : "–"} />
            </AppViewCard>
            <ClubsChangeStadium
              leagueId={leagueId}
              teamId={teamId}
              stadiumId={stadiumId}
              stadiumCity={stadiumCity}
              stadium={stadium}
              onRefetchCard={onRefetch}
            />
          </Space>
        </Col>
        <Col span={12}>
          <div className={s.card}>
            <Space direction={"horizontal"} size={16}>
              <AppViewCard label={"Логотип клуба:"} sharedStyles={s.logo}>
                <AppImagePreview
                  sharedStyles={s.logo}
                  imageURL={logoUrl}
                  useS3
                />
              </AppViewCard>
              <ClubsBackground
                background={background}
                leagueId={leagueId}
                teamId={teamId}
                onRefetchCard={onRefetch}
              />
              <ClubsStadiumPhoto
                url={stadiumPhoto}
                leagueId={leagueId}
                stadiumId={stadiumId}
                onRefetchCard={onRefetch}
              />
            </Space>

            {children}
          </div>
        </Col>
      </Row>
    </Space>
  );
};
