import { AimOutlined } from "@ant-design/icons";
import { ResourceProps } from "@refinedev/core";
import { nameLeagueMap } from "./Lib";

export const whereToLookResource: ResourceProps = {
  name: "where-to-look",
  meta: {
    icon: <AimOutlined />,
    label: "Где смотреть?",
  },
};

export const lookResourceLeagueGenerator = (
  type: "first-league" | "second-league" | "third-league"
): ResourceProps => {
  return {
    name: `${type}`,
    list: `/where-to-look/${type}`,
    show: `/where-to-look/${type}/show/:id`,
    create: `/where-to-look/${type}/create`,
    edit: `/where-to-look/${type}/edit/:id`,
    meta: {
      parent: "where-to-look",
      label: nameLeagueMap[type],
    },
  };
};
