import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Tooltip,
} from "antd";
import { FC } from "react";
import {
  CloseOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { TextField, useForm, useModal } from "@refinedev/antd";
import { ClubsMediaCard } from "./Clubs-Media-Card";
import { errorNotification, successNotification } from "../../../../../helpers";
import { mediaTypeSelect } from "../../../../Matches/Lib";
import { ClubMedia } from "../../../Lib";
import s from "./Clubs-Media.module.scss";
import { AppImageUploader, EFileTypes } from "../../../../../components";
import { v4 as randomId } from "uuid";
import {
  arrayLengthValidator,
  noSpecialCharValidator,
} from "../../../../../validators";

const { Title, Paragraph } = Typography;

type MatchesMediaProps = {
  onRefetchCard: () => void;
  teamId: number;
  leagueId: number;
  media: ClubMedia[];
  hashTags: string[];
};

export const ClubsMedia: FC<MatchesMediaProps> = ({
  media,
  onRefetchCard,
  teamId,
  leagueId,
  hashTags,
}) => {
  const { modalProps, show, close } = useModal();

  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "create",
    resource: "clubsMedia",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "clubsMediaDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Медиа блок добавлен",
        type: "success",
      },
      callback: () => {
        close();
        onRefetchCard();
        form.resetFields();
      },
    }),
    ...errorNotification({}),
  });

  const selectOptions = hashTags?.map((value) => {
    return { value, label: value };
  });

  const watchType = Form.useWatch("type", formProps.form);

  const watchLength = Form.useWatch("photos", formProps.form);
  const stopAdding = watchLength?.length <= 9;

  const handleChangeType = () => {
    form.setFieldValue("url", null);
    form.setFieldValue("videoUrl", null);
  };

  const afterClose = () => {
    form.resetFields();
  };

  return (
    <>
      <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
        <Space
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Title level={5}>Медиа</Title>
            <Tooltip title="Максимальный размер фото 5Мб. Видео добавляются как ссылки на сторонние ресурсы. Ссылки на youtube, vk запускаются в плеере приложения, остальные ссылки открываются в браузере. По тэгам медиа можно использовать поиск в Медиацентре">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          {Boolean(media?.length) && (
            <Button onClick={show} icon={<PlusOutlined />}>
              Добавить медиа
            </Button>
          )}
        </Space>
        {!media?.length ? (
          <Card
            style={{
              textAlign: "center",
              display: "flex",
              height: 200,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space direction={"vertical"} size={16}>
              <Title level={5}>Медиа отсутствуют</Title>
              <Button onClick={show} icon={<PlusOutlined />}>
                Добавить медиа
              </Button>
            </Space>
          </Card>
        ) : (
          <Row gutter={[8, 8]}>
            {media?.map((content, index) => {
              return (
                <ClubsMediaCard
                  selectOptions={selectOptions}
                  key={content.id}
                  media={content}
                  count={index}
                  onRefetchCard={onRefetchCard}
                />
              );
            })}
          </Row>
        )}
      </Space>

      <Modal
        title={"Добавление медиа"}
        onCancel={close}
        afterClose={afterClose}
        {...modalProps}
        footer={<></>}
      >
        <Form
          style={{
            width: "100%",
          }}
          {...formProps}
          initialValues={{
            type: "LINK",
            photos: [],
            url: "",
            videoUrl: "",
            teamId,
            leagueId,
          }}
          layout={"vertical"}
        >
          <Form.Item name={"teamId"} hidden />
          <Form.Item name={"leagueId"} hidden />
          <Form.Item name={"type"} label={"Тип медиа"}>
            <Select
              onChange={handleChangeType}
              placeholder="Выберите тип медиа"
              notFoundContent={"Тип не найден"}
            >
              {mediaTypeSelect.map((translation) => {
                return (
                  <Select.Option key={translation.key} value={translation.key}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <TextField value={translation.value} />
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {watchType === "PHOTO" && (
            <>
              <Form.Item
                label={
                  <div>
                    Обложка альбома {/*  */}
                    <Tooltip title="Максимальный размер фото 5Мб.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                name={"url"}
                rules={[
                  {
                    required: true,
                    message: "Укажите изображение",
                  },
                ]}
              >
                <AppImageUploader
                  type="TEAM"
                  entityId={`${teamId}/${randomId()}`}
                  serviceType={EFileTypes.CLUBS}
                  cropperProps={{
                    modalTitle: "Редактировать изображение",
                  }}
                  uploadProps={{
                    multiple: false,
                    children: "Прикрепите фото",
                    listType: "picture-card",
                  }}
                />
              </Form.Item>
              <Space size={8} direction="vertical" style={{ width: "100%" }}>
                <Paragraph className={s.newsBlockUpperHeader}>
                  <div>
                    <span className={s.requiredStar}>*</span> Фото альбома{" "}
                    {/*  */}
                    <Tooltip title="Максимальный размер фото 5Мб.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </Paragraph>

                <Form.List
                  name="photos"
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 1) {
                          message.error("Должен быть хотя бы одно изображение");
                          return Promise.reject(
                            new Error("Должен быть хотя бы одно изображение")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      <div className={s.imageContainer}>
                        {fields.map((field) => (
                          <div key={field.key} className={s.appImageWrapper}>
                            <div
                              className={s.appImageDelete}
                              onClick={(event) => {
                                remove(field.name);
                                event.stopPropagation();
                              }}
                            >
                              <CloseOutlined
                                style={{
                                  color: "var(--danger)",
                                }}
                              />
                            </div>
                            <Form.Item
                              name={[field.name, "url"]}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <span style={{ fontSize: "10px" }}>
                                      Укажите изображение
                                    </span>
                                  ),
                                },
                              ]}
                            >
                              <AppImageUploader
                                type="TEAM"
                                entityId={`${teamId}/${randomId()}`}
                                serviceType={EFileTypes.CLUBS}
                                cropperProps={{
                                  modalTitle: "Редактировать изображение",
                                }}
                                uploadProps={{
                                  multiple: false,
                                  children: "Прикрепите фото",
                                  listType: "picture-card",
                                }}
                              />
                            </Form.Item>
                          </div>
                        ))}
                        {stopAdding && (
                          <div
                            className={s.addImageContainer}
                            onClick={() => add()}
                          >
                            <PlusOutlined />
                            Добавить фото
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Form.List>
              </Space>
            </>
          )}

          {watchType === "LINK" && (
            <>
              <Form.Item
                label={
                  <>
                    Превью&nbsp;
                    <Tooltip title="Максимальный размер фото 5Мб">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                name={"url"}
                rules={[
                  {
                    required: true,
                    message: "Укажите превью",
                  },
                ]}
              >
                <AppImageUploader
                  type="TEAM"
                  entityId={`${teamId}/${randomId()}`}
                  serviceType={EFileTypes.CLUBS}
                  cropperProps={{
                    modalTitle: "Редактировать изображение",
                  }}
                  uploadProps={{
                    multiple: false,
                    children: "Прикрепите фото",
                    listType: "picture-card",
                  }}
                />
              </Form.Item>
              <Form.Item
                name={"videoUrl"}
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Ссылка медиа{" "}
                    <Tooltip
                      title={
                        <>
                          {`1. Добавление ссылки на ВК.
                      Для добавления ссылки необходимо авторизироваться в ВК
                      Ссылка добавляется на ролик через кнопку Share – Export
                      Общий формат ссылки в вк <iframe src="https://vk.com/video_ext.php?oid=-29484355&id=456247241&hd=2" width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
                      Добавляется только выделенная часть https://vk.com/video_ext.php?oid=-29484355&id=456247241&hd=2 .`}
                          <br />
                          {
                            "2.Добавление ссылки на Youtube. Должна начинаться с (https://) youtu.be// или  (https://) www.youtube.com"
                          }
                        </>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Необходимо указать ссылку",
                  },
                ]}
              >
                <Input placeholder={"Укажите ссылку"}></Input>
              </Form.Item>
            </>
          )}
          <Form.Item
            name={"title"}
            label={
              <div>
                Заголовок{" "}
                <Tooltip
                  title="Не более 100 символов и не менее 5 символов. Поле не должно
              содержать специальных символов."
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Необходимо указать заголовок",
              },
              {
                max: 100,
                min: 5,
                message: "Не более 100 символов и не менее 5 символов",
              },
              noSpecialCharValidator,
            ]}
          >
            <Input placeholder={"Укажите заголовок"}></Input>
          </Form.Item>
          <Form.Item
            name={"hashTags"}
            label={"Хэштеги"}
            rules={[arrayLengthValidator(3, "Хэштегов не может быть больше 3")]}
          >
            <Select
              placeholder={"Укажите теги"}
              showSearch
              mode={"multiple"}
              options={selectOptions}
              notFoundContent={
                <div
                  style={{
                    width: "100%",
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Укажите хэштеги
                </div>
              }
            />
          </Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            Добавить медиа
          </Button>
        </Form>
      </Modal>
    </>
  );
};
