import { List, TextField, useTable } from "@refinedev/antd";
import axAPI from "../../../../api/axios-instance";

import { useEffect, useState } from "react";
import { Table } from "antd";
import { v4 as randomId } from "uuid";
import { errorNotification } from "../../../../helpers";
import s from "./Melbet-List.module.scss";
import { MapTeam } from "./Map-Team/Map-Team";
import { useList } from "@refinedev/core";
import { ClubRow } from "../../../Clubs/Lib";

type MelbetRowType = {
  leagueId: number;
  melbetTeam: string;
  fnlTeam: {
    teamId: number;
    teamName: string;
    teamCity: string;
  };
};
export const MelbetList = () => {
  const [datasource, setDatasource] = useState<Array<any>>([]);
  const [seasonId, setSeasonId] = useState<number>();
  const [leagueTeam, setLeagueTeam] = useState<
    { title: string; teamId: number; city: string }[]
  >([]);

  const { tableQueryResult } = useTable<any>({
    dataProviderName: "mappingMelbetDataProvider",
    resource: "melbet",
    syncWithLocation: false,
    meta: {
      leagueId: 100,
    },
    ...errorNotification({
      errorData: {
        errorKeys: [
          {
            errorCode: "typeMismatch",
            message: "Неверное значение при чтении id пользователя",
          },
        ],
      },
    }),
  });

  const { data, refetch: refetchClubs } = useList({
    dataProviderName: "clubsDataProvider",
    meta: { leagueId: 100, seasonId: seasonId },
  });

  useEffect(() => {
    refetchClubs<ClubRow[]>().then(({ data }) => {
      const teams = data?.data.map((team) => {
        return {
          title: team.title,
          teamId: team.teamId,
          city: team.city,
        };
      });
      setLeagueTeam(teams || []);
    });
  }, [seasonId]);

  const fetchData = async () => {
    const { data } = await tableQueryResult.refetch();
    const clubs = data?.data as {
      fnlTeam: string;
      melbetTeam: string;
      leagueId: number;
    }[];

    if (clubs) {
      await setDatasource(clubs);
    }
    return clubs;
  };

  useEffect(() => {
    fetchData().then((clubs) => {
      const leagueId = clubs?.[0].leagueId;
      axAPI.get(`cms/info/allActiveSeason?leagueId=${leagueId}`).then((res) => {
        const seasonId = res.data?.[0]?.seasonId;
        if (seasonId) setSeasonId(seasonId);
      });
    });
  }, []);

  return (
    <List headerButtons={<></>} breadcrumb={false} title={"Мелбет"}>
      <div style={{ color: "grey", fontSize: 12, paddingBottom: 16 }}>
        Данные нужно маппить в начале каждого сезона. Отображается список команд
        в Мелбет.
        <br /> Нажать кнопку Смапить команду → Выбрать название команды в ФНЛ →
        Нажать кнопку Смапить команду
      </div>
      <Table<MelbetRowType>
        dataSource={datasource}
        rowClassName={(record, index, indent) =>
          !record.fnlTeam ? s.needMapping : ""
        }
        pagination={false}
        loading={tableQueryResult.isFetching}
        rowKey={() => randomId()}
        locale={{
          emptyText: <div style={{ margin: 56 }}>Команды не найдены</div>,
        }}
        showSorterTooltip={false}
      >
        <Table.Column<MelbetRowType>
          width={100}
          title="Команда FNL"
          render={(_, record) => (
            <TextField value={record?.fnlTeam?.teamName || "–"} />
          )}
        />
        <Table.Column<MelbetRowType>
          width={100}
          title="Город команды FNL"
          render={(_, record) => (
            <TextField value={record?.fnlTeam?.teamCity || "–"} />
          )}
        />
        <Table.Column<MelbetRowType>
          width={100}
          title="Команда Melbet"
          render={(_, record) => <TextField value={record.melbetTeam || "–"} />}
        />
        <Table.Column<MelbetRowType>
          width={40}
          title={"Действие"}
          align={"center"}
          render={(_, record) => (
            <MapTeam
              {...record}
              refetchList={fetchData}
              leagueTeam={leagueTeam}
            />
          )}
        />
      </Table>
    </List>
  );
};
