import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { LeagueStaffType } from "../Lib";
import dayjs from "dayjs";
import { recursiveUprateOrd } from "../Lib/helpers";
import { dateFormat } from "helpers";

export const employeesPresidiumDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const url = "cms/league/staff";

      try {
        const { data } = await httpClient.get(url);
        const result = data.filter((employee: LeagueStaffType) =>
          employee.departments.some(
            (department) => department.departmentId == -1
          )
        );
        return {
          data: result,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, meta }) => {
      const { id } = meta as { id: number };

      const url = "cms/league/staff/person";

      try {
        const { data: response } = await httpClient.get(url, {
          params: { staffId: id },
        });
        // const oneResource = data.find(
        //   (item: LeagueStaffType) => item.staffId == id
        // );

        const data = await {
          ...response,
          teamDay: response.teamDay && dayjs(response.teamDay),
          birthday: response.birthday && dayjs(response.birthday),
        };

        return {
          data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      meta: {
        employeesData: LeagueStaffType[];
      };
      variables: any;
    }) => {
      const { employeesData } = meta;

      try {
        const res = await httpClient.post("cms/league/staff", {
          ...variables,
          ord: Number(variables.ord),
          teamDay: dateFormat(variables.teamDay, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          birthday: dateFormat(
            variables.birthday,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
        });

        await httpClient.post("cms/league/staff/dept", {
          departmentId: -1,
          staffId: res.data.id,
          ord: Number(variables.ord),
        });

        await recursiveUprateOrd(
          variables.ord,
          employeesData,
          async (data) =>
            await httpClient.post("cms/league/staff/dept", {
              departmentId: -1,
              staffId: data.staffId,
              ord: data.ord,
            })
        );

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      meta,
      variables,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
        employeesData: LeagueStaffType[];
      };
      variables: any;
    }) => {
      const url = "cms/league/staff";

      const { id, employeesData } = meta;
      const currentIndex = employeesData.findIndex(
        (employee) => employee.staffId == id
      );
      if (currentIndex != -1) employeesData.splice(currentIndex, 1);

      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          id,
          teamDay: dateFormat(variables.teamDay, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          birthday: dateFormat(
            variables.birthday,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
        });

        await httpClient.post("cms/league/staff/dept", {
          departmentId: -1,
          staffId: id,
          ord: Number(variables.ord),
        });

        await recursiveUprateOrd(
          variables.ord,
          employeesData,
          async (data) =>
            await httpClient.post("cms/league/staff/dept", {
              departmentId: -1,
              staffId: data.staffId,
              ord: data.ord,
            })
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/staff";
      try {
        const { data } = await httpClient.delete(url, {
          params: { staffId: id },
        });

        return {
          data: {
            ...data,
            teamDay: data.teamDay
              ? dayjs(data?.data.birthday, "YYYY-MM-DD").format(
                  "YYYY-MM-DDTHH:mm:ssZ[Z]"
                )
              : data.teamDay,
            birthday: data.birthday
              ? dayjs(data?.data.birthday, "YYYY-MM-DD").format(
                  "YYYY-MM-DDTHH:mm:ssZ[Z]"
                )
              : data.birthday,
          },
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
