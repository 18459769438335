import { useTable } from "@refinedev/antd";
import { Form, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";
import { arrayLengthValidator } from "../../../validators";

type NewCreateHashTagsProps = {
  disabled?: boolean;
};
export const NewCreateHashTags: FC<NewCreateHashTagsProps> = ({
  disabled = false,
}) => {
  const { tableProps, searchFormProps, setFilters } = useTable<any>({
    dataProviderName: "hashTagsDataProvider",
    resource: "hashtags",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const hashTags = tableProps.dataSource;

  const selectOptions = hashTags?.map((value) => {
    return { value, label: value };
  });

  return (
    <Form.Item
      name={"hashTags"}
      label={
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          Хэштеги{" "}
          <Tooltip
            title={
              "Если в хэштеги добавлен хэштег команды, то пользователю придет уведомление о новости по команде"
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      }
      rules={[
        // { required: true, message: "Укажите хэштеги" },
        arrayLengthValidator(3, "Хэштегов не может быть больше 3"),
      ]}
    >
      <Select
        disabled={disabled}
        placeholder={"Укажите теги"}
        showSearch
        mode={"multiple"}
        options={selectOptions}
        notFoundContent={
          <div
            style={{
              width: "100%",
              height: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Укажите хэштеги
          </div>
        }
      />
    </Form.Item>
  );
};
