import { Show, useForm } from "@refinedev/antd";
import {
  errorNotification,
  s3Path,
  successNotification,
} from "../../../helpers";

import { Button, Col, Flex, Form, Input, Row, Space, Upload } from "antd";
import { AppTitle } from "../../../components";
import { PhilosophyCreateRequest } from "../Lib/types";
import { useContext, useEffect, useState } from "react";
import { useNavigation } from "@refinedev/core";
import { EnvContext } from "providers";
import axAPI from "../../../api/axios-instance";
import { UploadOutlined } from "@ant-design/icons";

export const PartnerEdit = () => {
  const { push } = useNavigation();
  const { THUMBOR_URL } = useContext(EnvContext);
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileUrl, setFileUrl] = useState<string>("");

  const { formProps, saveButtonProps, form, formLoading } =
    useForm<PhilosophyCreateRequest>({
      resource: "partner",
      action: "edit",
      id: "",
      meta: { fileUrl: fileUrl },
      dataProviderName: "partnerDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Изменения успешно сохранены",
          type: "success",
        },
        callback: () => push("/league-info/partner"),
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    });

  const watchFile = form.getFieldValue("file");
  if (typeof watchFile == "string" && !fileUrl) setFileUrl(watchFile);

  useEffect(() => {
    if (!fileList.length) setFileUrl(watchFile);
  }, [fileList]);

  async function uploadFile(file: File, type: string) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const result = await axAPI.post("/cms/image/upload/type", formData, {
        params: { type },
      });
      return Promise.resolve({ s3id: result.data });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return (
    <Show
      isLoading={formLoading}
      title={
        <AppTitle
          isLoading={formLoading}
          canGoBack
          routerLink={"/league-info/partner"}
        >
          Редактирование философии
        </AppTitle>
      }
      breadcrumb={false}
      headerButtons={<></>}
      footerButtons={null}
      goBack={false}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
        }}
        layout={"vertical"}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={"title"} label={"Заголовок"}>
              <Input />
            </Form.Item>

            <Form.Item
              name={"phone"}
              label={"Телефон:"}
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"email"}
              label={"Email:"}
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item name={"fax"} label={"Факс:"} style={{ width: "100%" }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Flex justify="space-between">
              <div
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                Файл:
                <a
                  href={s3Path({
                    path: fileUrl,
                    original: true,
                    thumbor: THUMBOR_URL,
                  })}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    minHeight: "32px",
                  }}
                >
                  {fileUrl?.replace(/.*\//, "")}
                </a>
              </div>
              <div style={{ maxWidth: "300px", width: "100%" }}>
                <Upload
                  customRequest={(e: any) => {
                    uploadFile(e.file as File, "DOCUMENTS").then((res) => {
                      setFileUrl(res.s3id);
                      e.onSuccess("ok");
                    });
                  }}
                  accept=".pdf"
                  fileList={fileList}
                  onChange={({ fileList }) => setFileList(fileList)}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </div>
            </Flex>
            <Form.Item label="Текст" name={"content"}>
              <Input.TextArea rows={10} placeholder={"Укажите контент"} />
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <Button type={"primary"} htmlType={"submit"}>
            Отредактировать
          </Button>
        </Space>
      </Form>
    </Show>
  );
};
