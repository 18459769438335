import { useTable, List, CreateButton, TextField } from "@refinedev/antd";
import { useMatch } from "react-router-dom";
import { useNavigation } from "@refinedev/core";
import { Space, Table } from "antd";

import { nameLeagueTitle, ListLinkType, leagueIndex } from "../Lib";
import { AppImagePreview } from "components";
import { LinksDelete } from "./LinksDelete";

export function ListLinks() {
  const match = useMatch("/:parent/:league/*");
  const resource = match?.params.league || "first-league";

  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<ListLinkType[]>({
    dataProviderName: "whereToLookDataProvider",
    resource: "where-to-look",
    meta: {
      leagueId: leagueIndex[`${resource}`],
    },
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const handleNavigateToCreate = (resource: string) => {
    push(`/where-to-look/${resource}/create`);
  };

  return (
    <List
      title={nameLeagueTitle[`${resource}`]}
      resource={"where-to-look"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton
          resource={"where-to-look"}
          onClick={() => handleNavigateToCreate(resource)}
        >
          Добавить ссылку
        </CreateButton>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Ссылки отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<ListLinkType>
          dataIndex={""}
          width={300}
          key="logoUrl"
          title={"Иконка"}
          render={(value) => {
            return <AppImagePreview imageURL={value.logoUrl} useS3={true} />;
          }}
        />
        <Table.Column<ListLinkType>
          width={150}
          dataIndex={"contentType"}
          key="contentType"
          title="Тип контента"
          render={(value) => (
            <TextField
              value={
                value == "BROADCAST"
                  ? "Прямая трансляция"
                  : "Дополнительный контент"
              }
            />
          )}
        />
        <Table.Column<ListLinkType>
          width={200}
          dataIndex={"title"}
          key="title"
          title="Наименование ресурса"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<ListLinkType>
          width={300}
          dataIndex={"url"}
          key="url"
          title="Ссылка на ресурс"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<ListLinkType>
          width={250}
          dataIndex={"name"}
          fixed={"right"}
          key="show"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <LinksDelete
                id={Number(record.id)}
                name={record.title}
                leagueName={resource}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
