import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { PhilosophyCreateRequest } from "../Lib/types";

export const partnerDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getOne: async ({ resource, id }) => {
      try {
        const { data: response } = await httpClient.get("/cms/league/partners");
        const data = await { ...response };
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      variables,
      meta,
    }: {
      id: string;
      resource: string;
      variables: PhilosophyCreateRequest;
      meta: {
        fileUrl: string;
      };
    }) => {
      const { fileUrl } = meta;
      const url = "/cms/league/partners";

      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          file: fileUrl,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
