import { EditButton, Show, useForm } from "@refinedev/antd";
import {
  dateFormat,
  DayMonthYearHoursAndMinutes,
  errorNotification,
  successNotification,
} from "../../../../helpers";

import { Col, Form, Input, Row, Space } from "antd";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../../components";
import { v4 as randomId } from "uuid";
import { PhilosophyBlockCreate } from "../Philosophy-Create/Philosophy-Block-Create";
import { PhilosophyCreateRequest } from "../../Lib/types";
import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";
import dayjs from "dayjs";
import { useNavigation } from "@refinedev/core";

export const PhilosophyShow = () => {
  const { push } = useNavigation();

  const { formProps, saveButtonProps, form, formLoading } =
    useForm<PhilosophyCreateRequest>({
      resource: "philosophy",
      action: "edit",
      id: "",
      dataProviderName: "philosophyDataProvider",
      redirect: false,
      ...successNotification({
        successData: {
          message: "Размещение успешно создано",
          type: "success",
        },
      }),
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
    });

  const watchReleaseDate = useWatch("newsDate", form);
  const isPublic = useWatch("isPublic", form);

  const renderDate = useMemo(() => {
    if (watchReleaseDate === "Invalid Date") {
      return dateFormat();
    }
    return dateFormat(watchReleaseDate, DayMonthYearHoursAndMinutes);
  }, [watchReleaseDate]);

  return (
    <Show
      isLoading={formLoading}
      title={<AppTitle isLoading={formLoading}>Философия</AppTitle>}
      breadcrumb={false}
      headerButtons={
        <Space direction={"horizontal"}>
          <EditButton onClick={() => push("/league-info/philosophy/edit")}>
            Редактировать
          </EditButton>
        </Space>
      }
      footerButtons={null}
      goBack={false}
    >
      <Form
        {...formProps}
        initialValues={{
          newsDate: dayjs(),
          ...formProps.initialValues,
          leagueId:
            formProps.initialValues?.leagueId === null
              ? 0
              : formProps.initialValues?.leagueId,
        }}
        layout={"vertical"}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Space size={16} direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                name={"mainPhotoUrl"}
                label={"Главное фото"}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: "Укажите изображение",
                    // },
                  ]
                }
              >
                <AppImageUploader
                  type="NEWS"
                  disabled
                  entityId={`${randomId()}`}
                  serviceType={EFileTypes.NEWS}
                  cropperProps={{
                    modalTitle: "Редактировать изображение",
                  }}
                  uploadProps={{
                    multiple: false,
                    children: "Изображение новости",
                    listType: "picture-card",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Заголовок"
                rules={[
                  { required: true, message: "Укажите заголовок" },
                  {
                    min: 5,
                    max: 100,
                    message: "Заголовок может быть от 5 до 100 символов",
                  },
                ]}
                name={"title"}
              >
                <Input.TextArea
                  disabled
                  rows={2}
                  placeholder={"Укажите заголовок"}
                />
              </Form.Item>

              <PhilosophyBlockCreate
                setDisabled={true}
                form={form}
                isPublic={isPublic}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Show>
  );
};
