import { Show, useForm } from "@refinedev/antd";
import { Button, Col, Form, Input, Row, Select, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMatch } from "react-router-dom";
import { v4 as randomId } from "uuid";
import { useNavigation } from "@refinedev/core";

import { errorNotification, successNotification } from "../../../helpers";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../components";
import { ListLinkType, leagueIndex } from "../Lib";
import { noSpecialCharValidator } from "../../../validators";

export const ListLinksCreate = () => {
  const route = useMatch("/:parent/:league/create");
  const { goBack, push } = useNavigation();
  const leagueId = Number(leagueIndex[`${route?.params.league}`]);

  const { formProps, formLoading, form } = useForm<ListLinkType>({
    resource: "where-to-look",
    action: "create",
    meta: { leagueId },
    dataProviderName: "whereToLookDataProvider",
    redirect: "list",
    ...successNotification({
      successData: { message: "Ресурс добавлен", type: "success" },
      callback: () => {
        close();
        form.resetFields();
        goBack();
        push(`/where-to-look/${route?.params.league}`);
      },
    }),
    ...errorNotification({}),
  });

  return (
    <Show
      isLoading={formLoading}
      title={
        <AppTitle isLoading={formLoading} canGoBack>
          {"Добавить ресурс"}
        </AppTitle>
      }
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={"logoUrl"}
              label={
                <>
                  Иконка&nbsp;
                  <Tooltip title="Рекомендуется загружать изображения с одинаковым разрешением (например 800*600)">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[{ required: true, message: "Укажите изображение" }]}
            >
              <AppImageUploader
                type="PARTNERS"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{ modalTitle: "Редактировать иконку" }}
                uploadProps={{
                  multiple: false,
                  children: "Иконка",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name="contentType"
              label={"Тип контента"}
              rules={[
                { required: true, message: "Необходимо выбрать тип контента" },
              ]}
            >
              <Select placeholder="Выберите тип контента">
                <Select.Option key={"BROADCAST"} value={"BROADCAST"}>
                  Прямая трансляция
                </Select.Option>
                <Select.Option key={"CONTENT"} value={"CONTENT"}>
                  Дополнительный контент
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name={"title"}
              label={"Название:"}
              style={{ width: "100%" }}
              rules={[
                { required: true, message: "Необходимо указать название" },
                {
                  max: 30,
                  min: 3,
                  message: "Не более 30 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите название"}></Input>
            </Form.Item>
            <Form.Item
              name={"url"}
              label={"Ссылка на сайт:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать ссылку на сайт",
                },
              ]}
            >
              <Input placeholder={"Укажите ссылку на сайт"}></Input>
            </Form.Item>
            <Space>
              <Button type={"primary"} htmlType={"submit"}>
                {"Добавить"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Show>
  );
};
