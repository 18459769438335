import { TextField, useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import { FC } from "react";
import { Button, Form, Input, Modal, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import { socialLinksValidator } from "../../../../../validators";

type selectType = { key: string; value: string };

const socialLinksOptions: selectType[] = [
  {
    key: "VK",
    value: "Вконтакте",
  },
  {
    key: "YOUTUBE",
    value: "Youtube",
  },
  {
    key: "TELEGRAM",
    value: "Телеграмм",
  },
];

type ClubsSocialLinksAddProps = {
  teamId: number;
  leagueId: number;
  onRefetchCard: () => void;
};
export const ClubsSocialLinksAdd: FC<ClubsSocialLinksAddProps> = ({
  teamId,
  leagueId,
  onRefetchCard,
}) => {
  const { modalProps, show, close } = useModal();

  const { formProps, form } = useForm<{
    url: string;
  }>({
    action: "create",
    resource: "socialLinks",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "socialLinksDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: " Ссылка добавлена",
        type: "success",
      },
      callback: () => {
        close();
        form.resetFields();
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const typeLink = useWatch("typeLink", form);

  return (
    <Space
      direction={"horizontal"}
      size={16}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <>
        <Button onClick={show} icon={<PlusOutlined />}>
          Редактирование ссылок
        </Button>
        <Modal
          title={"Редактирование ссылки"}
          onCancel={close}
          {...modalProps}
          footer={<></>}
        >
          <Form
            style={{
              width: "100%",
            }}
            {...formProps}
            layout={"vertical"}
          >
            <Form.Item
              name={"typeLink"}
              label={"Тип ссылки:"}
              rules={[
                {
                  required: true,
                  message: "Необходимо выбрать тип ссылки",
                },
              ]}
            >
              <Select allowClear placeholder="Выберите тип ссылки">
                {socialLinksOptions.map((link) => {
                  return (
                    <Select.Option key={link.key} value={link.key}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <TextField value={link.value} />
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={"url"}
              label={"Ссылка на соц.сеть"}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать ссылку",
                },
                socialLinksValidator(typeLink),
              ]}
            >
              <Input placeholder={"Укажите ссылку на соц.сеть"}></Input>
            </Form.Item>
            <Button type={"primary"} htmlType={"submit"}>
              Добавить ссылку
            </Button>
          </Form>
        </Modal>
      </>
    </Space>
  );
};
