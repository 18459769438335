import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const clubsPhotoStadiumDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: { url: string; stadiumId: number; leagueId: number };
      meta: {
        stadiumId: number;
        leagueId: number;
      };
    }) => {
      const updateUrl = "/cms/stadium/photo";
      const { leagueId, stadiumId } = meta;

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      id: number;
      resource: string;
      meta: { leagueId: number; stadiumId: number };
    }) => {
      const url = "/cms/stadium/photo";
      const { leagueId, stadiumId } = meta;
      try {
        const { data } = await httpClient.delete(`${url}`, {
          params: { leagueId, stadiumId },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
