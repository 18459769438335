import { Show, useForm } from "@refinedev/antd";
import { useOne } from "@refinedev/core";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { useMatch } from "react-router-dom";
import { v4 as randomId } from "uuid";

import { errorNotification, successNotification } from "../../../helpers";
import {
  AppImageUploader,
  AppRichText,
  AppTitle,
  EFileTypes,
  richTextTemplate,
} from "../../../components";
import { LeagueSocialShowType } from "../Lib";
import { noSpecialCharValidator } from "../../../validators";

export const SocialShow = () => {
  const route = useMatch("/league-info/social-responsibility/show/:id");
  const id = Number(route?.params.id);

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<LeagueSocialShowType>({
      resource: "social-responsibility",
      action: "edit",
      id,
      meta: {
        id: id,
      },
      dataProviderName: "socialResponsibilityDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: "Сотрудник изменен",
          type: "success",
        },
      }),
      ...errorNotification({}),
    });

  const { data, isLoading, refetch } = useOne<LeagueSocialShowType>({
    resource: "social-responsibility",
    id,
    meta: {
      id: id,
    },
    dataProviderName: "socialResponsibilityDataProvider",
    ...errorNotification(),
  });

  return (
    <Show
      isLoading={isLoading}
      title={
        <AppTitle isLoading={isLoading} canGoBack>
          Просмотр проекта
        </AppTitle>
      }
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={"icon"}
              initialValue={data?.data.icon}
              rules={[
                {
                  required: true,
                  message: "Загрузите фото сотрудника",
                },
              ]}
            >
              <AppImageUploader
                type="PARTNERS"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{
                  modalTitle: "Редактировать фото",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Фото",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"title"}
              initialValue={data?.data.title}
              label={"Название:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо название",
                },
                {
                  max: 50,
                  min: 3,
                  message: "Не более 50 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите название"}></Input>
            </Form.Item>
            <Form.Item
              name={"url"}
              initialValue={data?.data.url}
              label={"Сайт партнера:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать сайт партнера",
                },
              ]}
            >
              <Input placeholder={"Укажите сайт партнера"}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"description"} label={"Описание:"}>
              <AppRichText
                editorTemplate={richTextTemplate.backofficeNews}
                richTextProps={{
                  placeholder: "Укажите описание",
                  setOptions: {
                    buttonList: richTextTemplate.backofficeNews,
                    font: ["TT Norms Pro", "TT Runs"],
                    colorList: ["#FFFFFF", "#F6F6F6", "#9EA8B4", "#5D636A"],
                    linkProtocol: "https://",
                    linkRelDefault: { default: "no-follow" },
                    defaultStyle:
                      "font-family: TT Norms Pro, sans-serif; background-color: #383838; color: #FFFFFF; font-size: 16px;",
                    fontSize: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                  },
                }}
              />
            </Form.Item>
          </Col>
          <Space>
            <Button type={"primary"} htmlType={"submit"}>
              {"Отредактировать"}
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
