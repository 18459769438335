import { FC, useState } from "react";
import { TextField, useForm } from "@refinedev/antd";
import { AppViewCard } from "../../../../../components";
import { errorNotification, successNotification } from "../../../../../helpers";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import s from "./Clubs-Change-Stadium.module.scss";
import { ClubsDeleteEntity } from "../Clubs-Delete-Entity/Clubs-Delete-Entity";
import { useList } from "@refinedev/core";
import { StadiumType } from "pages/Clubs/Lib";

type ClubsChangeStadiumProps = {
  leagueId: number;
  teamId: number;
  stadium: string;
  stadiumCity: string;
  stadiumId: number;
  onRefetchCard: () => void;
};

export const ClubsChangeStadium: FC<ClubsChangeStadiumProps> = ({
  teamId,
  leagueId,
  stadiumId,
  stadium,
  stadiumCity,
  onRefetchCard,
}) => {
  const [edit, setEdit] = useState(false);

  const { formProps, saveButtonProps, form, formLoading } = useForm<
    StadiumType[]
  >({
    action: "create",
    resource: "clubs",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "clubsStadiumDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Стадион изменен",
        type: "success",
      },
      callback: () => {
        setEdit(false);
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const { data: stadiumList, isFetching: isFetchingStadiumList } =
    useList<StadiumType>({
      resource: "clubs",
      dataProviderName: "clubsStadiumDataProvider",
      meta: { leagueId: leagueId },
    });

  const handleEdit = () => {
    setEdit(true);
  };

  const handleStopEdit = () => {
    setEdit(false);
    // form.setFieldValue("color", color.value);
  };

  const handleRefetchOnDelete = () => {
    onRefetchCard();
    // form.setFieldValue("color", null);
  };

  // const mapColors = useCallback(
  //   (fieldColor: string) => {
  //     if (!fieldColor) {
  //       return "–";
  //     }
  //
  //     const findColor = colorTypeSelect.find(
  //       ({ key, value }) => key === fieldColor
  //     );
  //
  //     return `${findColor?.value} (${fieldColor})`;
  //   },
  //   [color]
  // );

  return (
    <>
      {!edit ? (
        <>
          <AppViewCard label={"Стадион:"}>
            <div className={s.colorViewContainer}>
              <div className={s.colorText}>
                <TextField
                  style={{ width: "100%" }}
                  value={stadiumId ? `${stadium} (${stadiumCity})` : "-"}
                />
              </div>
              <div className={s.colorText}>
                <EditOutlined
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    color: "var(--accent-main)",
                  }}
                  onClick={handleEdit}
                />
                {stadiumId && (
                  <ClubsDeleteEntity
                    onRefetch={handleRefetchOnDelete}
                    deleteId={{ leagueId, teamId }}
                    message={"Стадион успешно удален"}
                    okText={"Удалить стадион"}
                    modalText={"Вы уверены, что хотите удалить стадион?"}
                    dataProviderName={"clubsStadiumDataProvider"}
                  />
                )}
              </div>
            </div>
          </AppViewCard>
        </>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            <Form
              style={{
                width: "100%",
                display: "flex",
                alignItems: "end",
                gap: 8,
              }}
              {...formProps}
              initialValues={{
                teamId,
                leagueId,
              }}
              layout={"vertical"}
            >
              <Form.Item name={"teamId"} hidden />
              <Form.Item name={"leagueId"} hidden />

              <Form.Item
                name={"stadiumId"}
                label="Стадион:"
                style={{ flex: 1, marginBottom: 0 }}
              >
                <Select
                  showSearch
                  filterOption={(input: string, option?: { label: string }) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Выберите стадион"
                  notFoundContent={"Стадион не найден"}
                  allowClear
                  options={stadiumList?.data.map((stadium: StadiumType) => ({
                    value: stadium.id,
                    label: `${stadium.title} (${stadium.city})`,
                  }))}
                />
              </Form.Item>

              <Button type={"primary"} htmlType={"submit"}>
                Изменить стадион
              </Button>
              <div className={s.iconContainer}>
                <CloseOutlined
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    color: "var(--accent-main)",
                  }}
                  onClick={handleStopEdit}
                />
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  );
};
