import {
  ShowButton,
  useTable,
  List,
  CreateButton,
  TextField,
} from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import { Space, Table } from "antd";

import { LeagueSocialType } from "../Lib";
import { AppImagePreview } from "components";
import { LinksDelete } from "./LinksDelete";

export function SocialResponsibilityList() {
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<LeagueSocialType[]>({
    dataProviderName: "socialResponsibilityDataProvider",
    resource: "social-responsibility",
    meta: {},
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const handleNavigateToCreate = () => {
    push("/league-info/social-responsibility/create");
  };

  const handleNavigateToEdit = (id: string) => {
    push(`/league-info/social-responsibility/show/${id}`);
  };

  return (
    <List
      title={"Социальная ответственность"}
      resource={"social-responsibility"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton
          resource={"social-responsibility"}
          onClick={() => handleNavigateToCreate()}
        >
          Добавить проект
        </CreateButton>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Ссылки отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<LeagueSocialType>
          dataIndex={""}
          width={150}
          key="icon"
          title={"Иконка"}
          render={(value) => {
            return <AppImagePreview imageURL={value.icon} useS3={true} />;
          }}
        />
        <Table.Column<LeagueSocialType>
          width={450}
          dataIndex={"title"}
          key="title"
          title="Название"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<LeagueSocialType>
          width={250}
          dataIndex={"name"}
          fixed={"right"}
          key="show"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                onClick={() => handleNavigateToEdit(String(record.id))}
                size={"small"}
                block
              >
                Смотреть / Редактировать
              </ShowButton>
              <LinksDelete id={Number(record.id)} name={record.title} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
