import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const docsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const url = "cms/league/documents";

      try {
        const { data } = await httpClient.get(url);

        const result: any[] = [];
        data.forEach((typeList: any) => {
          typeList.documents.forEach((document: any) =>
            result.push({ ...document, type: typeList.type })
          );
        });

        return {
          data: result,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: any;
      meta: {
        fileUrl: string;
      };
    }) => {
      const { title, type } = variables;
      const { fileUrl } = meta;

      const { data } = await httpClient.get("cms/league/documents");
      const ids: any[] = [];
      data.forEach((typeList: any) => {
        typeList.documents.forEach((document: any) => ids.push(document.id));
      });

      const id = Math.max(...ids) + 1;

      try {
        await httpClient.post("cms/league/documents", {
          id,
          title,
          type,
          file: fileUrl,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "cms/league/documents";
      try {
        const { data } = await httpClient.delete(url, {
          params: { docId: id },
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
