import { Show, useForm } from "@refinedev/antd";
import { useCustom, useOne } from "@refinedev/core";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { useMatch } from "react-router-dom";
import { v4 as randomId } from "uuid";
import axAPI from "../../../../api/axios-instance";

import {
  DayMonthYear,
  errorNotification,
  successNotification,
} from "../../../../helpers";
import {
  AppImageUploader,
  AppRichText,
  AppTitle,
  AppViewCard,
  EFileTypes,
  richTextTemplate,
} from "../../../../components";
import { LeagueStaffEditType, LeagueStaffType } from "../../Lib";
import { noSpecialCharValidator } from "../../../../validators";
import { useEffect, useState } from "react";
import { getAllDepts } from "pages/Leagues/Lib/helpers";

export const EmployeeTeamShow = () => {
  const route = useMatch("/league-info/employees/team/show-employee/:id");
  const id = Number(route?.params.id);
  const [ord, setOrd] = useState<number>();
  const [dept, setDept] = useState<number>();
  const [deptData, setDeptData] = useState<any>();

  function getOrd() {
    axAPI
      .get("cms/league/dept/staff", {
        params: { departmentType: "TEAM" },
      })
      .then((data) => {
        const depts: any[] = [data?.data];
        const ordData = getAllDepts(depts || []);
        setDeptData(ordData);
        ordData.forEach((dept) => {
          const ord = dept.staff.find(
            (employee: LeagueStaffType) => employee.staffId == id
          )?.ord;
          if (typeof ord == "number") {
            setOrd(ord);
            setDept(dept.id);
          }
        });
      });
  }
  useEffect(() => {
    getOrd();
  }, []);

  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<LeagueStaffEditType>({
      resource: "employees",
      action: "edit",
      id,
      meta: {
        id: id,
        deptData: deptData,
        oldDeptId: dept,
      },
      dataProviderName: "employeesTeamDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: "Сотрудник изменен",
          type: "success",
        },
        callback: () => {
          getOrd();
        },
      }),
      ...errorNotification({}),
    });

  const { data, isLoading, refetch } = useOne<LeagueStaffEditType>({
    resource: "employees",
    id,
    meta: {
      id: id,
    },
    dataProviderName: "employeesTeamDataProvider",
    ...errorNotification(),
  });

  const { data: deptsList, refetch: refetchRsTeams } = useCustom({
    dataProviderName: "employeesTeamDataProvider",
    url: "team",
    method: "get",
  });

  return (
    <Show
      isLoading={isLoading}
      title={
        <AppTitle isLoading={isLoading} canGoBack>
          Изменение сотрудника
        </AppTitle>
      }
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Flex gap={"small"}>
              <AppViewCard label={"Превью фото"} style={{ flexGrow: 1 }}>
                <Form.Item
                  name={"photo"}
                  rules={[
                    {
                      required: true,
                      message: "Загрузите фото сотрудника",
                    },
                  ]}
                >
                  <AppImageUploader
                    type="STAFF"
                    entityId={`${randomId()}`}
                    serviceType={EFileTypes.PARTNERS}
                    cropperProps={{
                      modalTitle: "Редактировать фото",
                    }}
                    uploadProps={{
                      multiple: false,
                      children: "Превью фото",
                      listType: "picture-card",
                    }}
                  />
                </Form.Item>
              </AppViewCard>
              <AppViewCard label={"Главное фото"} style={{ flexGrow: 1 }}>
                <Form.Item
                  name={"mainPhoto"}
                  rules={[
                    {
                      required: true,
                      message: "Загрузите фото сотрудника",
                    },
                  ]}
                  style={{ flexGrow: 1 }}
                >
                  <AppImageUploader
                    type="STAFF"
                    entityId={`${randomId()}`}
                    serviceType={EFileTypes.PARTNERS}
                    cropperProps={{
                      modalTitle: "Редактировать фото",
                    }}
                    uploadProps={{
                      multiple: false,
                      children: "Главное фото",
                      listType: "picture-card",
                    }}
                  />
                </Form.Item>
              </AppViewCard>
            </Flex>
            <Form.Item
              name={"name"}
              label={"ФИО сотрудника:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо ФИО сотрудника",
                },
                {
                  max: 50,
                  min: 3,
                  message: "Не более 50 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите ФИО сотрудника"}></Input>
            </Form.Item>
            <Form.Item
              name={"jobTitle"}
              label={"Должность:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать должность",
                },
              ]}
            >
              <Input placeholder={"Укажите должность"}></Input>
            </Form.Item>
            <Form.Item
              name={"birthday"}
              label={"Дата рождения"}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать дату рождения",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                format={DayMonthYear}
                style={{ width: "100%" }}
                placeholder={"Дата рождения"}
              />
            </Form.Item>
            <Form.Item
              name={"teamDay"}
              label={"Присоединился к ФНЛ"}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать дату присоединения к ФНЛ",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                format={DayMonthYear}
                style={{ width: "100%" }}
                placeholder={"Присоединился к ФНЛ"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="departmentId"
              label={"Департамент"}
              initialValue={dept}
            >
              <Select placeholder="Выберите департамент">
                {deptsList?.data.map((department: any) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={"ord"}
              initialValue={ord}
              label={"Порядок отображения в департаменте"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message:
                    "Необходимо указать порядок отображения в департаменте",
                },
              ]}
            >
              <Input
                placeholder={"Укажите порядок отображения в департаменте"}
                type="number"
              ></Input>
            </Form.Item>
            <Form.Item
              name={"biography"}
              label={"Биография:"}
              style={{ width: "100%" }}
              rules={[
                { required: true, message: "Необходимо указать биографию" },
              ]}
            >
              <AppRichText
                editorTemplate={richTextTemplate.backofficeNews}
                richTextProps={{
                  placeholder: "Укажите биографию",
                  setOptions: {
                    buttonList: richTextTemplate.backofficeNews,
                    font: ["TT Norms Pro", "TT Runs"],
                    colorList: ["#FFFFFF", "#F6F6F6", "#9EA8B4", "#5D636A"],
                    linkProtocol: "https://",
                    linkRelDefault: { default: "no-follow" },
                    defaultStyle:
                      "font-family: TT Norms Pro, sans-serif; background-color: #383838; color: #FFFFFF; font-size: 16px;",
                    fontSize: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                  },
                }}
              />
            </Form.Item>
          </Col>
          <Space>
            <Button type={"primary"} htmlType={"submit"}>
              {"Отредактировать"}
            </Button>
          </Space>
        </Row>
      </Form>
    </Show>
  );
};
