import { DeleteOutlined } from "@ant-design/icons";
import { useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import { useDelete } from "@refinedev/core";
import { FC } from "react";
import { Modal } from "antd";

type ClubsDeleteEntityProps = {
  onRefetch: () => void;
  deleteId: { leagueId: number; teamId: number };
  message: string;
  okText: string;
  modalText: string;
  dataProviderName:
    | "clubsDescriptionDataProvider"
    | "clubsAchievementsDataProvider"
    | "clubsTrophiesDataProvider"
    | "clubsColorDataProvider"
    | "clubsStadiumDataProvider";
};
export const ClubsDeleteEntity: FC<ClubsDeleteEntityProps> = ({
  onRefetch,
  deleteId,
  message,
  okText,
  modalText,
  dataProviderName,
}) => {
  const { modalProps, show, close } = useModal();
  const { mutate: deleteMutate } = useDelete();

  const handleDelete = () => {
    deleteMutate({
      dataProviderName,
      resource: "clubs",
      id: "",
      meta: {
        ...deleteId,
      },
      ...successNotification({
        successData: {
          message,
          type: "success",
        },
        callback: () => {
          onRefetch();
          close();
        },
      }),
      ...errorNotification({}),
    });
  };

  return (
    <>
      <DeleteOutlined
        onClick={show}
        style={{
          color: "var(--danger)",
          fontSize: 20,
          cursor: "pointer",
        }}
      />
      <Modal
        okText={okText}
        cancelText={"Отмена"}
        onOk={handleDelete}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        {modalText}
      </Modal>
    </>
  );
};
