import { List, TextField, useTable } from "@refinedev/antd";

import { useEffect, useState } from "react";
import { Button, Form, Select, Space, Table } from "antd";
import { v4 as randomId } from "uuid";
import s from "./Russtat-List.module.scss";
import { useWatch } from "antd/es/form/Form";
import { MapSeason } from "./Map-Season/Map-Season";
import { getDefaultFilter, useCustom, useNavigation } from "@refinedev/core";
import { ArrowRightOutlined } from "@ant-design/icons";

type RusstatRowType = {
  fnlLeagueId: number;
  fnlLeagueTitle: string;
  fnlSeasonId: number;
  fnlSeasonTitle: string;
  rsLeagueId: number;
  rsLeagueTitle: string;
  rsSeasonId: number;
  rsSeasonTitle: string;
};

type SeasonsType = { title: string; seasonId: number };
type RusstatLeagueType = { id: string; title: string };

export const RusstatList = () => {
  const { push, replace } = useNavigation();
  const [rsLeagues, setRsLeagues] = useState<RusstatLeagueType[]>([]);
  const [rsSeasons, setRsSeasons] = useState<RusstatLeagueType[]>([]);

  const { tableQueryResult, tableProps, searchFormProps, setFilters, filters } =
    useTable<any>({
      dataProviderName: "mappingRusstatDataProvider",
      resource: "russtat",
      syncWithLocation: true,
      filters: {
        initial: [
          {
            field: "leagueId",
            value: "100",
            operator: "eq",
          },
        ],
        defaultBehavior: "replace",
      },
    });
  const watchLeagueId = useWatch("leagueId", searchFormProps.form);

  const { data: rsLeagueData, refetch: refetchRsLeague } = useCustom({
    dataProviderName: "mappingRusstatDataProvider",
    url: "league",
    method: "get",
  });

  const { data: rsSeasonData, refetch: refetchRsSeason } = useCustom({
    dataProviderName: "mappingRusstatDataProvider",
    url: "league/season",
    method: "get",
  });

  useEffect(() => {
    refetchRsLeague<RusstatLeagueType[]>().then(({ data }) => {
      const leagues = data?.data as RusstatLeagueType[];
      setRsLeagues(leagues || []);
    });
  }, []);

  useEffect(() => {
    refetchRsSeason<RusstatLeagueType[]>().then(({ data }) => {
      const seasons = data?.data as RusstatLeagueType[];
      setRsSeasons(seasons || []);
    });
  }, []);

  const handleChangeFilter = (value: number) => {
    setFilters([
      {
        value: value || undefined,
        field: "leagueId",
        operator: "eq",
      },
    ]);
  };
  const handleNavigateToClubs = (fnlLeagueId: number, fnlSeasonId: number) => {
    push(`/mapping/russtat/clubs/${fnlLeagueId}/${fnlSeasonId}`);
  };
  return (
    <List headerButtons={<></>} breadcrumb={false} title={"Сезоны Рустат"}>
      <div style={{ color: "grey", fontSize: 12, paddingBottom: 16 }}>
        {"Начинать с 2022-2023 (До этого в Рустат нет статистики)."}
        <br />
        {`Выбрать фильтр
        лиги → Нажать кнопку "Смаппить сезон" → При маппинге указывать ту же
        лигу, что и в фильтре → Указать сезон → Нажать кнопку "Смапить сезон"`}
      </div>
      <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
        <Form
          {...searchFormProps}
          initialValues={{
            leagueId: getDefaultFilter("leagueId", filters) || "100",
          }}
          layout="horizontal"
        >
          <Form.Item name="leagueId" label={"Лига"} className={s.input}>
            <Select
              showArrow={true}
              placeholder="Выберите лигу"
              onChange={handleChangeFilter}
            >
              <Select.Option key={"100"} value={"100"}>
                Первая лига
              </Select.Option>
              <Select.Option key={"200"} value={"200"}>
                Вторая лига А
              </Select.Option>
              <Select.Option key={"300"} value={"300"}>
                Вторая лига Б
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Table<RusstatRowType>
          {...tableProps}
          rowClassName={(record, index, indent) =>
            !record.rsLeagueId ? s.needMapping : ""
          }
          pagination={false}
          loading={tableQueryResult.isFetching}
          rowKey={() => randomId()}
          locale={{
            emptyText: <div style={{ margin: 56 }}>Команды не найдены</div>,
          }}
          showSorterTooltip={false}
        >
          <Table.Column<RusstatRowType>
            width={100}
            title="Сезоны FNL"
            render={(_, record) => (
              <TextField value={record.fnlSeasonTitle || "–"} />
            )}
          />
          <Table.Column<RusstatRowType>
            width={100}
            title="Сезоны Рустат"
            render={(_, record) => (
              <TextField value={record.rsSeasonTitle || "–"} />
            )}
          />
          <Table.Column<RusstatRowType>
            width={40}
            title={"Действие"}
            align={"center"}
            render={(_, record) => (
              <Space direction={"vertical"}>
                <MapSeason
                  {...record}
                  leagueId={watchLeagueId}
                  refetchList={() => tableQueryResult.refetch()}
                  rsLeagues={rsLeagues}
                  rsSeasons={rsSeasons}
                />
                <Button
                  size="small"
                  block
                  onClick={() =>
                    handleNavigateToClubs(
                      record.fnlLeagueId,
                      record.fnlSeasonId
                    )
                  }
                >
                  Смотреть команды
                  <ArrowRightOutlined />
                </Button>
              </Space>
            )}
          />
        </Table>
      </Space>
    </List>
  );
};
